import { Avatar, Button, Group, Menu, Paper, Text, Title, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import * as React from 'react';

interface SimpleHeaderProps{
    title? : any;
    subtitle? : string;
    right?: any;
    Icon?: any;
    button?: { title: string; onClick?: any, [key: string]: any, variant?: string };
    buttons?: { title: string; onClick?: any, [key: string]: any, variant?: string }[];
    [key: string]: any;
}

export default function SimpleHeader(props : SimpleHeaderProps){
    let {Icon, title, subtitle, button, right, buttons, ...others} = props;
    const isMobile = useMediaQuery('(max-width: 900px)');
    const theme = useMantineTheme();
    buttons = buttons || (button ? [button] : [])

    const btn = (button) => <Button
        variant={button.variant? button.variant : 'filled'}
        size="sm"
        {...button}
    >{button.title}</Button>

    return <Paper p="lg" mb="md">
        <Group justify="apart" align="flex-start" style={{flexDirection: isMobile ? "column" : "row"}} {...others}>
            <Group style={{flex: 1}} align='center'>
                {Icon && <Avatar color="gray.0"><Icon size="1.5rem" /></Avatar>}
                <div>
                    {typeof title === "string" ? <Title order={3} c="gray.0">{title}</Title> : title}
                    {subtitle && <Text mt="xs" size="sm" c="gray.2">{subtitle}</Text>}
                </div>
            </Group>
            <Group align='flex-start'>
                {right}
                {buttons.map(btn)}
            </Group>
        </Group>
    </Paper>
}