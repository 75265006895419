import {
  Box,
  Button,
  darken,
  Dialog,
  Group,
  Image,
  lighten,
  luminance,
  MantineProvider,
  MantineThemeOverride,
  Modal,
  Progress,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useMemo } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Socket, connect } from "socket.io-client";
import usePersistState from "../hooks/usePersistState";
import ChoosePlan from "../pages/choose_plan";
import PublicPage from "../pages/public";
import Recharge from "../pages/recharge";
import { GET_COMPANY_INTEGRATION } from "../services/companies";
import { GET_ALL_USERS } from "../services/users";
import { useQuery } from "../utility/util";
import { useAuth } from "./auth.context";
import { useLanguage } from "./language.context";
import Intercom from "@intercom/messenger-js-sdk";

interface ConfirmDialogProps {
  (
    props: { text: string; buttonText?: string },
    callback: (props: { confirmed: boolean }) => void
  ): void;
}
interface ConfigsProps {
  primary: string;
  navbar?: string;
  image?: string;
  icon?: string;
  contrast?: string;
  brand?: string;
  name?: string;
}
interface AppContextProps {
  confirmDialog: ConfirmDialogProps;
  setColorScheme: (colorScheme: "dark" | "light") => void;
  socket: Socket;
  configs: ConfigsProps;
  mobileApp?: any;
  setConfigs: (vl: ConfigsProps) => any;
  registration?: ServiceWorkerRegistration;
  agents: any[];
  loadAgents: () => void;
  openedSidebar?: boolean;
  toggleOpenedSidebar?: () => void;
  expandImage: (url: string) => void;
  expandText: (text: string) => void;
  newOrder: any;
  agentRoles: any[];
  agentLanguages: any[];
  setNewOrder: (params: any) => void;
  notifyGaEvent: (params: any) => void;
  startPlan: (start?: boolean) => void;
  recharge: (start?: boolean) => void;
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const lsColorScheme = localStorage.getItem("@awaz/colorScheme") as
    | "light"
    | "dark";

  const [openedConfirm, setOpenedConfirm] = React.useState(null);
  const [newOrder, setNewOrder] = React.useState(null);
  const [startingPlan, setStartingPlan] = React.useState(false);
  const [recharge, setRecharge] = React.useState(false);
  const [importingFiles, setImportingFiles] = React.useState([]);
  const [agents, setAgents] = React.useState<any[]>([]);
  const [configs, setConfigs] = React.useState<ConfigsProps>({
    primary: "#000000",
  });
  const [openImage, setOpenImage] = React.useState<string>("");
  const [openText, setOpenText] = React.useState<string>("");
  const [mobileApp, setMobileApp] = React.useState<any>({});
  const [colorScheme, setColorScheme] = React.useState<"dark" | "light">(
    lsColorScheme || "light"
  );
  const adjustedPrimary = (() => {
    if (configs.primary === "#FFFFFF") {
      return darken(configs.primary, 0.25);
    } else if (configs.primary === "#000000") {
      return lighten(configs.primary, 0.25);
    }
    return configs.primary ?? lighten("#FFFFFF", 0.25);
  })();
  const contrast = (luminance(configs.primary) > 0.5 ? "#000000" : "#FFFFFF")
  const [registration, setRegistration] =
    React.useState<ServiceWorkerRegistration>(null);

  const darken1 = (luminance(adjustedPrimary) > 0.5) ? lighten(adjustedPrimary, 0.4) : darken(adjustedPrimary, 0.65);
  const darken2 = (luminance(adjustedPrimary) > 0.5) ? lighten(adjustedPrimary, 0.3) : darken(adjustedPrimary, 0.4);
  const dark: string[] = (luminance(configs.primary) > 0.5)
    ? [
      darken(adjustedPrimary, 0.8),
      darken(adjustedPrimary, 0.65),
      darken(adjustedPrimary, 0.4),
      darken(adjustedPrimary, 0.2),
      darken(adjustedPrimary, 0.1),
      adjustedPrimary,
      lighten(adjustedPrimary, 0.2),
      lighten(adjustedPrimary, 0.3),
      lighten(adjustedPrimary, 0.4),
      lighten(adjustedPrimary, 0.5),
    ]
    : [
      lighten(adjustedPrimary, 0.5),
      lighten(adjustedPrimary, 0.4),
      lighten(adjustedPrimary, 0.3),
      lighten(adjustedPrimary, 0.2),
      lighten(adjustedPrimary, 0.1),
      adjustedPrimary,
      darken(adjustedPrimary, 0.2),
      darken(adjustedPrimary, 0.4),
      darken(adjustedPrimary, 0.65),
      darken(adjustedPrimary, 0.8),
    ];
  const gray: string[] = (luminance(configs.primary) > 0.5)
    ? [
      contrast,
      darken(contrast, 0.1),
      darken(contrast, 0.2),
      darken(contrast, 0.3),
      darken(contrast, 0.4),
      darken(contrast, 0.5),
      darken(contrast, 0.6),
      darken(contrast, 0.7),
      darken(contrast, 0.8),
      darken(contrast, 0.9),
    ]
    : [
      lighten(contrast, 0.9),
      lighten(contrast, 0.8),
      lighten(contrast, 0.7),
      lighten(contrast, 0.6),
      lighten(contrast, 0.5),
      lighten(contrast, 0.4),
      lighten(contrast, 0.3),
      lighten(contrast, 0.2),
      lighten(contrast, 0.1),
      contrast,
    ];

  const query = useQuery();
  const theme: MantineThemeOverride = {
    fontFamily: "Inter, sans-serif",
    headings: { fontWeight: "bold" },
    primaryColor: "dark",
    colors: {
      gray: gray as any,
      dark: dark as any,
    },
    components: {
      Table: {
        styles: {
          root: {
            border: `1px solid ${darken(configs.primary, 0.4)}`,
            borderRadius: 15,
          },
          th: {
            backgroundColor: darken(configs.primary, 0.4),
            padding: '20px 10px',
            color: gray[1],
            textAlign: 'center',
          },
          td: {
            backgroundColor: darken(configs.primary, 0.3),
            color: gray[1],
            padding: '10px 10px',
          },
        }
      },
      Modal: {
        styles: {
          header: { backgroundColor: dark[7] },
          body: { backgroundColor: dark[7] },
        }
      },
      Checkbox: { styles: { label: { color: gray[6] } } },
      Switch: { styles: { label: { color: gray[6] } } },
      Slider: { styles: { markLabel: { color: gray[6], fontSize: 10 } } },
      TextInput: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      Textarea: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      DatePickerInput: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      DateTimePicker: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      TimeInput: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      MultiSelect: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      Select: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      PasswordInput: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      Box: { styles: { input: { color: gray[1], backgroundColor: dark[8] }, label: { color: gray[1] } } },
      Paper: { styles: { root: { borderRadius: '15px', backgroundColor: dark[7] } } },
    },
  }

  let fbc = query.get("fbclid");

  const [socket, setSocket] = React.useState<Socket>(null);

  const { role, user: currentUser, companyHost, loadUserData, user, userData } = useAuth();
  const { str } = useLanguage();

  const { pathname } = useLocation();
  const navigate = useNavigate();


  const gaTrackings = [
    "G-LY81V86E4J",
    companyHost?.ga,
  ].filter(nn => nn);

  const [openedSidebar, setOpenedSidebar] = usePersistState<boolean>(
    "@awaz/opened-sidebar",
    false
  );

  const loadAgents = (setLoading = true) => {
    if (role) {
      GET_ALL_USERS()
        .then((dt) => {
          setAgents(
            dt
              .filter(u => u?.profile?.title !== "Client")
              .sort((a, b) => (a.name > b.name ? 1 : -1))
          );
        })
        .catch((err) => {
          notifications.show({
            title: "Ops",
            message: err.message,
            color: "red",
          });
        });
    }
  };

  const confirmDialog: ConfirmDialogProps = (
    { text, buttonText = str("CONFIRM") },
    callback
  ) => {
    setOpenedConfirm({ text, buttonText, callback });
  };

  const toggleOpenedSidebar = () => {
    setOpenedSidebar(!openedSidebar);
  };

  const notifyGaEvent = (params: any) => {
    for (let trackingId of gaTrackings) {
      ReactGA.event(params, trackingId);
    }
  }

  useEffect(() => {
    GET_COMPANY_INTEGRATION("mobile-app")
      .then((res) => { setMobileApp(res) })
      .catch(err => { })
  }, [userData])

  useEffect(() => {
    for (let trackingId of gaTrackings) {
      ReactGA.initialize(trackingId);
      ReactGA.set({ debug: false });
    }
  }, [JSON.stringify(gaTrackings)]);

  useEffect(() => {
    for (let trackingId of gaTrackings) {
      ReactGA.pageview(pathname, [trackingId]);
    }
  }, [JSON.stringify(gaTrackings), pathname]);

  // useEffect(() => {
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/service-worker.js")
  //       .then((registration) => {
  //         setRegistration(registration);
  //       })
  //       .catch((error) => {
  //         console.error("Service worker registration failed:", error);
  //       });
  //   }
  // }, []);


  useEffect(() => {
    if (fbc) Cookies.set("fbc", fbc);
  }, [fbc])

  useEffect(() => {
  }, [socket]);

  useEffect(() => {
    if (userData?.user?.intercom_hash) {
      Intercom({
        app_id: 'xsndq4y9',
        user_id: userData?.user?._id,
        name: userData?.user?.name,
        email: userData?.user?.email,
        created_at: userData?.user?.createdAt,
        user_hash: userData?.user?.intercom_hash,
      });
    }
  }, [userData]);

  useEffect(() => {
    localStorage.setItem("@awaz/colorScheme", colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    if (currentUser) {
      const s = connect(process.env.REACT_APP_SERVER_URL, {
        extraHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
      setSocket(s);
    }
  }, [currentUser]);

  useEffect(loadAgents, [role]);

  useEffect(() => {
    setConfigs(c => ({
      ...c,
      name: userData?.company?.name,
      // primary: companyHost?.appearance?.colors?.primary || userData?.company?.appearance?.colors?.primary || "#FFFFFF",
      // primary: companyHost?.appearance?.colors?.primary || userData?.company?.appearance?.colors?.primary || "#5f3cc5",
      primary: companyHost?.appearance?.colors?.primary || userData?.company?.appearance?.colors?.primary || "#3b3d5d",
      navbar: companyHost?.appearance?.colors?.navbar || userData?.company?.appearance?.colors?.navbar || "white",
      image: companyHost?.image ?? userData?.company?.image,
      icon: companyHost?.icon ?? userData?.company?.icon,
    }))
  }, [companyHost, userData])

  const value = useMemo<AppContextProps>(() => {
    return {
      confirmDialog,
      setColorScheme,
      socket,
      mobileApp,
      registration,
      agents,
      openedSidebar,
      loadAgents,
      toggleOpenedSidebar,
      expandImage: (url) => setOpenImage(url),
      expandText: (text) => setOpenText(text),
      newOrder,
      setNewOrder,
      notifyGaEvent,
      agentRoles: [
        { value: "sales", label: str("AGENTS.ROLES.SALES") },
        { value: "customer-support", label: str("AGENTS.ROLES.CUSTOMER_SUPPORT") },
        { value: "other", label: str("AGENTS.ROLES.OTHER") },
      ],
      agentLanguages: [
        { "label": "English - United States", "value": "en-US" },
        { "label": "English - India", "value": "en-IN" },
        { "label": "English - United Kingdom", "value": "en-GB" },
        { "label": "German - Germany", "value": "de-DE" },
        { "label": "Spanish - Spain", "value": "es-ES" },
        { "label": "Spanish - Latin America", "value": "es-419" },
        { "label": "Hindi - India", "value": "hi-IN" },
        { "label": "Japanese - Japan", "value": "ja-JP" },
        { "label": "Portuguese - Portugal", "value": "pt-PT" },
        { "label": "Portuguese - Brazil", "value": "pt-BR" },
        { "label": "French - France", "value": "fr-FR" },
        { "label": "Chinese - China", "value": "zh-CN" },
        { "label": "Russian - Russia", "value": "ru-RU" },
        { "label": "Italian - Italy", "value": "it-IT" },
        { "label": "Korean - Korea", "value": "ko-KR" },
        { "label": "Dutch - Netherlands", "value": "nl-NL" },
        { "label": "Polish - Poland", "value": "pl-PL" },
        { "label": "Turkish - Turkey", "value": "tr-TR" },
        { "label": "Vietnamese - Vietnam", "value": "vi-VN" },
        { "label": "Multilingual (en and es)", "value": "multi" }
      ],
      // agentLanguages: [
      //   { value: "ar", label: "Arabic" },
      //   { value: "bg", label: "Bulgarian" },
      //   { value: "zh", label: "Chinese" },
      //   { value: "hr", label: "Croatian" },
      //   { value: "cs", label: "Czech" },
      //   { value: "da", label: "Danish" },
      //   { value: "nl", label: "Dutch" },
      //   { value: "en", label: "English" },
      //   { value: "fi", label: "Finnish" },
      //   { value: "fr", label: "French" },
      //   { value: "de", label: "German" },
      //   { value: "el", label: "Greek" },
      //   { value: "hi", label: "Hindi" },
      //   { value: "hu", label: "Hungarian" },
      //   { value: "id", label: "Indonesian" },
      //   { value: "it", label: "Italian" },
      //   { value: "ja", label: "Japanese" },
      //   { value: "ko", label: "Korean" },
      //   { value: "ms", label: "Malay" },
      //   { value: "no", label: "Norwegian" },
      //   { value: "pl", label: "Polish" },
      //   { value: "pt", label: "Portuguese" },
      //   { value: "ro", label: "Romanian" },
      //   { value: "ru", label: "Russian" },
      //   { value: "sk", label: "Slovak" },
      //   { value: "es", label: "Spanish" },
      //   { value: "sv", label: "Swedish" },
      //   { value: "ta", label: "Tamil" },
      //   { value: "tr", label: "Turkish" },
      //   { value: "uk", label: "Ukrainian" },
      //   { value: "vi", label: "Vietnamese" },
      // ],
      startPlan: (start = true) => {
        setStartingPlan(start);
        if (!start) loadUserData();
      },
      recharge: (start = true) => {
        setRecharge(start);
        if (!start) loadUserData();
      },
      configs: {
        ...configs,
        brand: {
          "black": "#000000",
          "green": "#00DB72",
          "yellow": "#FFD540",
          "violet": "#FF4D17",
          "white": "#FBFBFB",
          "blue": "#0A39FC",
          "pink": "#FC0A6B",
        }[configs.navbar ?? "white"],
        contrast,
      },
      setConfigs: (c) => {
        setConfigs(v => ({ ...v, ...c }));
      },
    };
  }, [
    configs,
    setConfigs,
    confirmDialog,
    setColorScheme,
    socket,
    newOrder,
    registration,
    agents,
    openedSidebar,
    toggleOpenedSidebar,
  ]);

  return (
    <MantineProvider
      withCssVariables
      theme={theme}
      defaultColorScheme="dark"
    // defaultColorScheme="light"
    // forceColorScheme="dark"
    >
      <AppContext.Provider value={value}>
        <>
          <>
            <Dialog
              opened={Boolean(openedConfirm)}
              withCloseButton={false}
              onClose={() => setOpenedConfirm(null)}
              size="lg"
              radius="md"
              zIndex={9999}
            >
              <Text size="sm" mb="sm">
                {openedConfirm?.text}
              </Text>

              <Group align="flex-end">
                <Button variant="outline" color="gray"
                  onClick={() => setOpenedConfirm(null)}
                >{str("CANCEL")}</Button>
                <Button
                  color="violet"
                  onClick={() => {
                    openedConfirm?.callback &&
                      openedConfirm?.callback({ confirmed: true });
                    setOpenedConfirm(null);
                  }}
                >
                  {openedConfirm?.buttonText}
                </Button>
              </Group>
            </Dialog>
          </>
        </>
        {(companyHost && !companyHost.active) ? <PublicPage /> : children}
        {/* <Helmet>
          {configs.name && <title>{configs.name}</title>}
          {configs.icon
            ? <link rel="icon" href={configs.icon} />
            : <link rel="icon" href={"/icon.png"} />}
        </Helmet> */}
        {/* {userData?.user && role?.profile?.title !== "Client" && process.env.REACT_APP_SUPORT_BOARD_ID && <Helmet>
          <script id="chat-init" src={`https://cloud.board.support/account/js/init.js?id=${process.env.REACT_APP_SUPORT_BOARD_ID}&name=${userData.user.name}`}></script>
        </Helmet>} */}
        <Dialog opened={importingFiles.length > 0}>
          {importingFiles.map((imp) => (
            <div>
              <div style={{ textAlign: "right" }}>
                <Text c="violet" size="sm">
                  {imp.done}/{imp.total}
                </Text>
              </div>
              <Progress color="violet" value={imp.percent} />
              <Text size="xs" style={{ textAlign: "right" }}>
                {{ themes: "Importação de pautas" }[imp.type]} -{" "}
                {imp.origin.toUpperCase()}
              </Text>
            </div>
          ))}
        </Dialog>
        <Modal
          opened={!!openImage}
          onClose={() => setOpenImage(null)}
          fullScreen
          size="xl"
        >{openImage && <TransformWrapper
          limitToBounds
        >
          {/* {({ zoomIn, zoomOut, resetTransform, ...rest }) => ( */}
          <TransformComponent>
            <Image
              // onClick={() => zoomIn()}
              src={openImage} height={"90vh"} width="100%" fit="contain" />
          </TransformComponent>
          {/* )} */}
        </TransformWrapper>}</Modal>
        <Modal
          opened={!!openText}
          onClose={() => setOpenText(null)}
          size="md"
        ><Box style={{ border: 10, background: "#F4F4F4" }} p="lg">
            {openText}
          </Box>
        </Modal>
        <Modal
          opened={startingPlan}
          overlayProps={{ backgroundOpacity: 0.5 }}
          onClose={() => setStartingPlan(false)}
          size="90vw"
          withCloseButton={false}
          styles={{ content: { background: `${configs.primary}` } }}
        >
          <ChoosePlan onClose={() => setStartingPlan(false)} />
        </Modal>
        <Modal
          opened={recharge}
          overlayProps={{ backgroundOpacity: 0.5 }}
          onClose={() => setRecharge(false)}
          size="90vw"
          withCloseButton={false}
          styles={{ content: { background: `${configs.primary}` } }}
        >
          <Recharge onClose={() => setRecharge(false)} />
        </Modal>
        <CookieConsent
          style={{
            background: configs.primary ?? '#12141d', fontWeight: 'bold'
          }}
          buttonStyle={{
            color: "white",
            background: 'transparent',
            fontWeight: "bold",
            border: '2px solid white',
            borderRadius: 8,
            padding: '5px 30px'
          }}
        >
          {str("COOKIES_DESCRIPTION")}{" "}
        </CookieConsent>
      </AppContext.Provider>
    </MantineProvider>
  );
};

export const useApp = () => useContext(AppContext);

export const useAgentVariables = () => {
  const [companyVariables, setCompanyVariables] = React.useState([]);

  const { userData } = useAuth();

  useEffect(() => {
    setCompanyVariables([
      { label: "Current Time", value: "CurrentTime" },
      { label: "Contact Name", value: "Contact.Name" },
      { label: "Contact First Name", value: "Contact.FirstName" },
      { label: "Contact Phone", value: "Contact.Phone" },
      { label: "Contact E-mail", value: "Contact.Email" },
      ...(userData?.company?.fields ?? []).map(f => ({ label: `Contact.${f.title}`, value: `Contact.${(f.title ?? "").replace(/ /g, "")}` }))
    ])
  }, [userData?.company?.fields]);

  return companyVariables;
};
