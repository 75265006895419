import {
  ActionIcon,
    Box,
    Button,
    Divider,
    Group,
    Menu,
    Text
} from "@mantine/core";
import React from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaPlus, FaTrash, FaWrench } from "react-icons/fa";
import { timezones } from "../../utility/util";
import { useAuth } from "../../contexts/auth.context";

export default function OthersSettings({ data, setData, actionOptions = [], whatsapps = [], calendars = [] }: any){
    const { str } = useLanguage();
    const { userData } = useAuth();

    return <>
      <Group mb="md">
        <FaWrench />
        <Text size="lg" fw="bold" style={{flex: 1}} c="gray.0">{str("AGENTS.VOICE_SETTINGS")}</Text>
      </Group>
      {/* <InputField
        name="knowledges"
        title="Knowledges Bases"
        mb="md"
        multiple
        fieldType={InputFieldTypes.SELECT}
        value={data?.knowledges ?? []}
        options={knowledges.map(k => ({ label: k.title, value: k._id }))}
        onChange={({knowledges}) => setData(na => ({ ...na, knowledges }))}
      /> */}
      
      {/* <InputField
        name="llm_websocket_url"
        title="llm_websocket_url"
        mb="md"
        value={data?.data?.retell?.agent?.llm_websocket_url}
        onChange={({llm_websocket_url}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, llm_websocket_url } } } }))}
      /> */}
      
      {/* <InputField
        name="agent_name"
        title="agent_name"
        mb="md"
        value={data?.data?.retell?.agent?.agent_name}
        onChange={({agent_name}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, agent_name } } } }))}
      /> */}
      
      <InputField
        name="voice_temperature"
        title={`${str('AGENTS.TONE_OF_VOICE')} (${parseFloat(data?.data?.retell?.agent?.voice_temperature ?? 1).toFixed(2)})`}
        mb="xl"
        fieldType={InputFieldTypes.SLIDE}
        min={1}
        marks={[
          { label: "Calm", value: 1 },
          { label: "Emotional", value: 100 },
        ]}
        value={(data?.data?.retell?.agent?.voice_temperature ?? 1) * 100 / 2}
        onChange={({voice_temperature}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, voice_temperature: voice_temperature*2/100 } } } }))}
      />
      
      <InputField
        name="voice_speed"
        title={`${str('AGENTS.VOICE_SPEED')} (${parseFloat(data?.data?.retell?.agent?.voice_speed ?? 1).toFixed(2)})`}
        mb="xl"
        fieldType={InputFieldTypes.SLIDE}
        min={25}
        marks={[
          { label: "Slow", value: 25 },
          { label: "Fast", value: 100 },
        ]}
        value={(data?.data?.retell?.agent?.voice_speed ?? 1) * 100 / 2}
        onChange={({voice_speed}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, voice_speed: voice_speed*2/100 } } } }))}
      />

      <InputField
        name="volume"
        title={`${str('AGENTS.VOLUME')} (${parseFloat(data?.data?.retell?.agent?.volume ?? 1).toFixed(2)})`}
        mb="xl"
        fieldType={InputFieldTypes.SLIDE}
        min={25}
        marks={[
          { label: "Low", value: 25 },
          { label: "Loud", value: 100 },
        ]}
        value={(data?.data?.retell?.agent?.volume ?? 1) * 100 / 2}
        onChange={({volume}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, volume: volume*2/100 } } } }))}
      />
      
      <InputField
        name="responsiveness"
        title={`${str('AGENTS.RESPONSIVENESS')} (${parseFloat(data?.data?.retell?.agent?.responsiveness ?? 1).toFixed(2)})`}
        mb="xl"
        fieldType={InputFieldTypes.SLIDE}
        marks={[
          { label: "Slow", value: 0 },
          { label: "Fast", value: 100 },
        ]}
        value={(data?.data?.retell?.agent?.responsiveness ?? 1)*100}
        onChange={({responsiveness}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, responsiveness: responsiveness/100 } } } }))}
      />
      
      <InputField
        name="interruption_sensitivity"
        title={`${str('AGENTS.INTERRUPTION_SENSITIVITY')} (${parseFloat(data?.data?.retell?.agent?.interruption_sensitivity ?? 1).toFixed(2)})`}
        mb="xl"
        fieldType={InputFieldTypes.SLIDE}
        marks={[
          { label: str('LOW'), value: 0 },
          { label: str('HIGH'), value: 100 },
        ]}
        value={(data?.data?.retell?.agent?.interruption_sensitivity ?? 1) * 100}
        onChange={({interruption_sensitivity}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, interruption_sensitivity: interruption_sensitivity/100 } } } }))}
      />
      
      <InputField
        name="enable_backchannel"
        title={str("AGENTS.BACKCHANNEL_TITLE")}
        mb="md"
        fieldType={InputFieldTypes.SWITCH}
        value={data?.data?.retell?.agent?.enable_backchannel}
        onChange={({enable_backchannel}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, enable_backchannel } } } }))}
      />
      <Text size="xs" mb="md" c="gray.3">{str("AGENTS.BACKCHANNEL_SUBTITLE")}</Text>
      
      <InputField
        name="end_call_after_silence"
        title={str("AGENTS.END_CALL_AFTER_SILENCE_TITLE")}
        mb="md"
        fieldType={InputFieldTypes.NUMBER}
        min={10}
        value={data?.data?.retell?.agent?.end_call_after_silence ?? 30}
        onChange={({end_call_after_silence}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, end_call_after_silence } } } }))}
      />
      <Text size="xs" mb="md" c="gray.3">{str("AGENTS.END_CALL_AFTER_SILENCE_SUBTITLE")}</Text>

      <InputField
        name="boosted_keywords"
        title={str("AGENTS.BOOSTED_KEYWORDS_TITLE")}
        mb="md"
        value={data?.data?.retell?.agent?.boosted_keywords}
        onChange={({boosted_keywords}) => setData(na => ({...na, data: { ...na.data, retell: {
          ...na?.data?.retell, agent: { ...na?.data?.retell?.agent, boosted_keywords } } } }))}
      />
      <Text size="xs" mb="md" c="gray.3">{str("AGENTS.BOOSTED_KEYWORDS_SUBTITLE")}</Text>

      {userData?.user?.isAdmin && <InputField
        fieldType={InputFieldTypes.SWITCH}
        name="own"
        title="Use AWS Servers"
        value={data?.data?.own}
        onChange={({own}) => setData(na => ({...na, data: { ...na.data, own } }))}
      />}
      
      
      {/* <Group mb="md">
        <Text fw="bold" style={{flex: 1}}>Variables</Text>
        <ActionIcon
          onClick={() => { setData(na => ({ ...na, data: { ...na?.data, variables: [...(na?.data?.variables ?? []), { type: "string" }] } })) }}
        ><FaPlus/></ActionIcon>
      </Group>
      
      {(data?.data?.variables ?? []).map((v, vi) => <Group>
        <InputField
          name="key"
          title="Key"
          mb="md"
          style={{flex: 1}}
          value={v?.key}
          onChange={({key}) => {
            setData(na => ({ ...na, data: { ...na?.data, variables: na.data?.variables.map((v2, vj) => vi === vj ? {...v2, key} : v2) } }))
          }}
        />
        <InputField
          name="title"
          title="Title"
          mb="md"
          style={{flex: 1}}
          value={v?.title}
          onChange={({title}) => {
            setData(na => ({ ...na, data: { ...na?.data, variables: na.data?.variables.map((v2, vj) => vi === vj ? {...v2, title} : v2) } }))
          }}
        />
      </Group>)} */}
    </>
}