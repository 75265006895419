import { ActionIcon, Badge, Box, Button, Grid, Group, Loader, Modal, Paper, Table, Tabs, Text, Tooltip } from '@mantine/core';
import { notifications, showNotification } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaEllipsisV, FaFileExport, FaListAlt, FaPlay, FaTag } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import AudioPlayer from '../components/audio_player';
import SimpleHeader from '../components/simple_header';
import { EXPORT_CALLS, GET_ALL_CALLS, GET_CALLS_DYNAMIC_FILTERS, RUN_AFTER_CALL } from '../services/calls';
import { formatMilliseconds, getExtenseDatetime, groupBy, toTitleCase, useQuery } from '../utility/util';
import InputField, { InputFieldTypes } from '../components/input_field';
import { GET_ALL_CAMPAIGNS } from '../services/campaigns';
import ConversationBox from '../components/conversations/conversation_box';
import moment from 'moment';
import { Buffer } from 'buffer';
import { useAuth } from '../contexts/auth.context';
import { useLanguage } from '../contexts/language.context';
import { FaFilter } from 'react-icons/fa6';
import { GET_ALL_AGENTS } from '../services/agents';

export default function CallsPage() {
    const [calls, setCalls] = useState<any>({});
    const [page, setPage] = useState(0);
    const [selectedTab, setSelectedTab] = useState("general");
    const [filters, setFilters] = useState<any>({
        status: [],
        direction: null,
        campaign: null,
        period: [],
    });
    const [openFilters, setOpenFilters] = useState<any>(null);
    const [done, setDone] = useState(false);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingCampaigns, setLoadingCampaigns] = useState(false);
    const [loadingDynamicFilters, setLoadingDynamicFilters] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [dynamicFilters, setDynamicFilters] = useState<any[]>([]);
    const [expandedCall, setExpandedCall] = useState<any>(null);
    const [extractedVariables, setExtractedVariables] = useState<any[]>([]);
    // const [quantity, setQuantity] = useState(10);

    const navigate = useNavigate();
    const search = useQuery();
    const { userData } = useAuth();
    const { str } = useLanguage();
    const campaignQuery = search.get("campaign");

    const key = `${filters.campaign};${filters.status.join("---")};${filters.direction};${filters.period.join("---")};;DF;;${dynamicFilters.filter(df => ({...filters?.extracted})[df.key] !== undefined).map(df => `${df.key}:${{...filters?.extracted}[df.key]}`).join("---")}`;

    const loadCalls = () => {
        setLoading(true);
        GET_ALL_CALLS({ ...filters, page })
        .then(({ items, total }) => {
            setLoading(false);
            setDone(items.length === 0);
            setTotal(total);
            setCalls(cs => ({
                ...cs,
                [key]: groupBy([...(cs[key] ?? []), ...items], c => c._id)
                    .map(g => g[1][0])
                    .sort((a,b) => a.createdAt > b.createdAt ? -1 : 1)
            }));
        })
        .catch(err => {
            setLoading(false);
            showNotification({ title: "Ops", message: err.message, color: "red" })
        })
    }

    const loadCampaigns = () => {
        setLoadingCampaigns(true);
        GET_ALL_CAMPAIGNS()
        .then(res => {
            setLoadingCampaigns(false);
            setCampaigns(res.filter(c => c.status !== -1));
        })
        .catch(err => {
            setLoadingCampaigns(false);
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const loadDynamicFilters = () => {
        setLoadingDynamicFilters(true);
        GET_CALLS_DYNAMIC_FILTERS()
        .then(res => {
            setLoadingDynamicFilters(false);
            setDynamicFilters(res);
        })
        .catch(err => {
            setLoadingDynamicFilters(false);
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const handleExportCsv = () => {
        setLoadingExport(true)
        EXPORT_CALLS({ ...filters, page })
        .then(res => {
            setLoadingExport(false)
            for(let file of res.files ?? []){
                if(file.url) window.open(file.url, "_blank");
                if(file.base64){
                    const byteCharacters = Buffer.from(file.base64, "base64");
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters[i];
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                
                    const blob = new Blob([byteArray], { type: file.contentType });

                    const url = URL.createObjectURL(blob);;
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${file.filename}`;
                    a.click();
                    URL.revokeObjectURL(url);
                }
            }
        })
        .catch(err => {
            setLoadingExport(false)
            notifications.show({ message: err.message, color: "red" })
        })
    }

    const afterCall = (callId) => {
        RUN_AFTER_CALL(callId)
        .then(res => {
            alert(JSON.stringify(res))
        })
        .catch(err => {
            notifications.show({ message: err.message, color: 'red' })
        });
    }
    
    useEffect(() => {
        const ev = [];
        for(let key in {...expandedCall?.extracted_variables}){
            ev.push({
                key,
                value: expandedCall?.extracted_variables[key],
            })
        }
        setExtractedVariables(ev);
    }, [expandedCall]);

    useEffect(() => {
        loadCampaigns();
    }, []);
    
    useEffect(() => {
        loadDynamicFilters();
    }, []);
    
    useEffect(() => {
        setFilters(f => ({ ...f, campaign: campaignQuery }));
    }, [campaignQuery]);

    useEffect(() => {
        loadCalls();
    }, [key, page])

    return <Box>
        <Paper mb="xl" p="md">
            <Grid align="flex-end">
                <Grid.Col span={{base: 12, md: 2}} ta="right">
                    <Tooltip label="Export data">
                        <Button fullWidth size="md" loading={loadingExport} onClick={() => {
                            handleExportCsv()
                        }} rightSection={<FaFileExport />}>{str("EXPORT")}</Button>
                    </Tooltip>
                </Grid.Col>
                
                <Grid.Col span={{base: 12, md: 3}}>
                    <InputField
                        name="campaign"
                        loading={loadingCampaigns}
                        placeholder={str("SEARCH")}
                        title={str("CAMPAIGNS.TITLE")}
                        fieldType={InputFieldTypes.SELECT}
                        value={filters?.campaign}
                        onChange={({ campaign }) => {
                            setPage(0);
                            setFilters(f => ({ ...f, campaign }));
                        }}
                        options={campaigns.map((c) => ({ label: `${c.title}`, value: c._id }))}
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 3}}>
                    <InputField
                        name="period"
                        title={str("PERIOD")}
                        fieldType={InputFieldTypes.DATERANGE}
                        value={filters?.period.map(p => p ? moment(p).toDate() : p)}
                        onChange={({ period }) => {
                            setFilters(f => ({ ...f, period: [
                                period[0] ? moment(period[0]).startOf("day").format() : null,
                                period[1] ? moment(period[1]).endOf("day").format() : null,
                            ] }));
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 3}}>
                    <InputField
                        name="status"
                        title={str("STATUS")}
                        fieldType={InputFieldTypes.SELECT}
                        value={filters?.status}
                        multiple
                        onChange={({ status }) => { setFilters(f => ({ ...f, status })); }}
                        options={[
                            { label: str(`CALLS.STATUS.NO-ANSWER`), value: 'no-answer' },
                            { label: str(`CALLS.STATUS.PICKUP`), value: 'pickup' },
                            { label: str(`CALLS.STATUS.OUTCOME`), value: 'outcome' },
                            { label: str(`CALLS.STATUS.BUSY`), value: 'busy' },
                            { label: str(`CALLS.STATUS.VOICEMAIL`), value: 'voicemail' },
                            { label: str(`CALLS.STATUS.FAILED`), value: 'failed' },
                        ]}
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 1}}>
                    <ActionIcon
                        size="xl"
                        color="dark"
                        onClick={() => { setOpenFilters(filters) }}
                    ><FaFilter /></ActionIcon>
                </Grid.Col>
            </Grid>
        </Paper>

        {/* {shownCalls.length === 0 && <div style={{ textAlign: 'center', padding: 40 }}>
            <UnstyledButton onClick={() => setNewContact({})}>
                <Paper p="lg" shadow="xs">
                    <Group>
                        <ActionIcon><FaEdit /></ActionIcon>
                        <Text c="gray">Create new call</Text>
                    </Group>
                    {searchContact.length > 0 && <Text ta="center" c="violet">{searchContact}</Text>}
                </Paper>
            </UnstyledButton>
        </div>} */}
        <Text size="lg" c="gray.1">{str("TOTAL_RESULTS")}: {total}</Text>
        <Table.ScrollContainer minWidth={100}>
            <Table mt="xl" styles={{tr: {  }, th: { fontSize: 16 }}}>
                <Table.Tr>
                    <Table.Th colSpan={2}>{str("CALLS.CONTACT")}</Table.Th>
                    <Table.Th>{str("CALLS.FROM")}</Table.Th>
                    <Table.Th>{str("CALLS.TO")}</Table.Th>
                    <Table.Th>{str("CALLS.DIRECTION")}</Table.Th>
                    <Table.Th>{str("CALLS.ORIGIN")}</Table.Th>
                    <Table.Th>{str("CALLS.COST_DURATION")}</Table.Th>
                    <Table.Th>{str("CALLS.DATE")}</Table.Th>
                    <Table.Th>{str("STATUS")}</Table.Th>
                    <Table.Th></Table.Th>
                </Table.Tr>
                {(calls[key] ?? []).map(c => 
                    <Table.Tr>
                        <Table.Td>
                            <AudioPlayer url={c?.recording_url}>
                                <ActionIcon disabled={!c?.recording_url} variant="outline" color="gray" size="lg"><FaPlay /></ActionIcon>
                            </AudioPlayer>
                        </Table.Td>
                        <Table.Td><Link to={`/contacts/${c?.contact?._id}`}><Text size="sm" c="gray.0">{c?.contact?.name}</Text></Link></Table.Td>
                        <Table.Td ta="center">{c.from ?? "web"}</Table.Td>
                        <Table.Td ta="center">{c.to ?? "web"}</Table.Td>
                        <Table.Td ta="center">{c.direction === "inbound" ? str("CALLS.INBOUND") : str("CALLS.OUTBOUND")}</Table.Td>
                        <Table.Td ta="center">{c.campaign ? c.campaign.title : str("CALLS.ROLEPLAY")} - {c?.agent?.title}</Table.Td>
                        <Table.Td ta="center">${(c?.total_price).toFixed(2)} / {formatMilliseconds((c?.duration ?? 0) * 1000)}</Table.Td>
                        <Table.Td ta="center">{getExtenseDatetime(c?.createdAt)}</Table.Td>
                        <Table.Td ta="center"><Badge color={{
                            "failed": "red",
                            "pickup": "blue",
                            "completed": "blue",
                            "outcome": "green",
                            "voicemail": "violet",
                            "busy": "yellow",
                            "no-answer": "yellow",
                        }[c.status as string] ?? "gray"} variant="filled">{str(`CALLS.STATUS.${(c.status ?? "").toUpperCase()}`)}</Badge></Table.Td>
                        <Table.Td>
                            <Group>
                                <ActionIcon color="dark" onClick={() => setExpandedCall(c)}><FaListAlt /></ActionIcon>
                                {
                                    userData?.user?.isAdmin &&
                                    <ActionIcon color="dark" onClick={() => afterCall(c._id)}><FaTag /></ActionIcon>
                                }
                            </Group>
                        </Table.Td>
                    </Table.Tr>
                )}
            </Table>
        </Table.ScrollContainer>

        {done ? <Text c="gray" size="md" mt="xl">{str("CALLS.NO_MORE")}...</Text> : <Button mt="xl" onClick={() => setPage(p => p+1)} loading={loading}>Load More</Button>}

        <Modal
            opened={!!expandedCall}
            onClose={() => setExpandedCall(null)}
            size="lg"
        >
            {
                <Tabs value={selectedTab ?? "general"} onChange={setSelectedTab}>
                    <Tabs.List>
                        <Tabs.Tab value="general">{str("CALLS.GENERAL")}</Tabs.Tab>
                        <Tabs.Tab value="transcription" disabled={!expandedCall?.transcription}>{str("CALLS.TRANSCRIPTION")}</Tabs.Tab>
                        <Tabs.Tab value="analysis" disabled={!expandedCall?.analysis && extractedVariables.length === 0}>{str("CALLS.ANALYSIS")}</Tabs.Tab>

                    </Tabs.List>
                    <Tabs.Panel value="general" pt="md" pb="md">
                        <Badge mt="md" color={{
                            "failed": "red",
                            "pickup": "blue",
                            "completed": "blue",
                            "outcome": "green",
                            "voicemail": "violet",
                            "busy": "yellow",
                            "no-answer": "yellow",
                        }[expandedCall?.status] ?? "gray"} variant="outline">{str(`CALLS.STATUS.${(expandedCall?.status ?? "").toUpperCase()}`)}</Badge>

                        <Text mt="sm">{str("CALLS.CONTACT")}: {expandedCall?.contact?.name}</Text>
                        <Text mt="sm">{str("CALLS.FROM")}: {expandedCall?.from ?? "web"}</Text>
                        <Text mt="sm">{str("CALLS.TO")}: {expandedCall?.to ?? "web"}</Text>
                        
                        <Text mt="sm">{str("CALLS.DIRECTION")}: {expandedCall?.direction === "inbound" ? str("CALLS.INBOUND") : str("CALLS.OUTBOUND")}</Text>
                        <Text mt="sm">{str("CALLS.ORIGIN")}: {expandedCall?.campaign ? expandedCall?.campaign.title : str("CALLS.ROLEPLAY")}</Text>
                        <Text mt="sm">{str("CALLS.COST_DURATION")}: ${(expandedCall?.total_price ?? 0).toFixed(2)} / {`0${((expandedCall?.duration ?? 0)/60).toFixed(0)}`.slice(-2)}:{`0${((expandedCall?.duration ?? 0)%60)}`.slice(-2)}</Text>
                        <Text mt="sm">{str("CALLS.DATE")}: {getExtenseDatetime(expandedCall?.createdAt)}</Text>

                        {expandedCall?.recording_url && <audio style={{width: '100%', marginTop: 20}} controls>
                            <source src={expandedCall?.recording_url}></source>    
                        </audio>}
                    </Tabs.Panel>

                    <Tabs.Panel value="transcription">
                        <ConversationBox
                            messages={(expandedCall?.transcription ?? [])}
                            reverse
                        />
                    </Tabs.Panel>

                    <Tabs.Panel value="analysis">
                        {expandedCall?.analysis && <>
                            <Group mt="md">
                                <Text style={{flex: 1}}>{str("CALLS.COMPLETION")}</Text>
                                <Badge variant="outline" color={{
                                    "Incomplete": "red",
                                    "Positive": "green",
                                    "Complete": "green",
                                    "Neutral": "yellow",
                                }[expandedCall?.analysis?.call_completion_rating] ?? "gray"}>{expandedCall?.analysis?.call_completion_rating}</Badge>
                            </Group>
                            <Text size="md" c="gray">{expandedCall?.analysis?.call_completion_rating_reason}</Text>
                            <Group mt="md">
                                <Text style={{flex: 1}}>{str("CALLS.AGENT_TASK_COMPLETION")}</Text>
                                <Badge variant="outline" color={{
                                    "Incomplete": "red",
                                    "Positive": "green",
                                    "Complete": "green",
                                    "Neutral": "yellow",
                                }[expandedCall?.analysis?.agent_task_completion_rating] ?? "gray"}>{str(`CALLS.ANALYSIS_STATUS.${(expandedCall?.analysis?.agent_task_completion_rating ?? "").toUpperCase()}`)}</Badge>
                            </Group>
                            <Text size="md" c="gray">{expandedCall?.analysis?.agent_task_completion_rating_reason}</Text>
                            <Group mt="md">
                                <Text style={{flex: 1}}>{str("CALLS.USER_SENTIMENT")}:</Text><Badge variant="outline">{expandedCall?.analysis?.user_sentiment}</Badge>
                            </Group>
                            {/* <Group mt="md">
                                <Text style={{flex: 1}}>{str("CALLS.AGENT_SENTIMENT")}:</Text><Badge variant="outline">{expandedCall?.analysis?.agent_sentiment}</Badge>
                            </Group> */}

                        </>}
                        {extractedVariables.length > 0 && <>
                            {extractedVariables.filter(v => v.key !== "awaz_outcome").map(v => <Box pt="md">
                                <Text size="md">{v.key === "awaz_outcome_reason" ? "Outcome Reason" : toTitleCase(v.key)}</Text>
                                <Text size="md" c="gray">{[false, true].includes(v.value) ? v.value ? "Yes" : "No" : v.value}</Text>
                            </Box>)}
                        </>}
                    </Tabs.Panel>
                </Tabs>
            }
        </Modal>

        <Modal
            opened={!!openFilters}
            onClose={() => setOpenFilters(null)}
        >
            <Group style={{flexDirection: 'column', alignItems: 'unset', justifyContent: 'unset'}}>
                <InputField
                    name="campaign"
                    loading={loadingCampaigns}
                    placeholder={str("SEARCH")}
                    title={str("CAMPAIGNS.TITLE")}
                    fieldType={InputFieldTypes.SELECT}
                    value={openFilters?.campaign}
                    onChange={({ campaign }) => {
                        setOpenFilters(f => ({ ...f, campaign }));
                    }}
                    options={campaigns.map((c) => ({ label: `${c.title}`, value: c._id }))}
                />

                <InputField
                    name="period"
                    title={str("PERIOD")}
                    fieldType={InputFieldTypes.DATERANGE}
                    value={openFilters?.period.map(p => p ? moment(p).toDate() : p)}
                    onChange={({ period }) => {
                        setOpenFilters(f => ({ ...f, period: [
                            period[0] ? moment(period[0]).startOf("day").format() : null,
                            period[1] ? moment(period[1]).endOf("day").format() : null,
                        ] }));
                    }}
                />

                <InputField
                    name="status"
                    title={str("STATUS")}
                    fieldType={InputFieldTypes.SELECT}
                    value={openFilters?.status}
                    multiple
                    onChange={({ status }) => { setOpenFilters(f => ({ ...f, status })); }}
                    options={[
                        { label: str(`CALLS.STATUS.NO-ANSWER`), value: 'no-answer' },
                        { label: str(`CALLS.STATUS.PICKUP`), value: 'pickup' },
                        { label: str(`CALLS.STATUS.OUTCOME`), value: 'outcome' },
                        { label: str(`CALLS.STATUS.BUSY`), value: 'busy' },
                        { label: str(`CALLS.STATUS.VOICEMAIL`), value: 'voicemail' },
                        { label: str(`CALLS.STATUS.FAILED`), value: 'failed' },
                    ]}
                />

                {
                    loadingDynamicFilters
                    ? <Loader />
                    : dynamicFilters.map(df => (
                        <InputField
                            name="vl"
                            title={df.key}
                            fieldType={InputFieldTypes.SELECT}
                            value={{...openFilters?.extracted}[df.key] !== undefined ? `${{...openFilters?.extracted}[df.key]}` : {...openFilters?.extracted}[df.key]}
                            onChange={({ vl }) => { setOpenFilters(f => ({ ...f, extracted: { ...f.extracted, [df.key]: vl === "true" ? true : vl === "false" ? false : undefined } })); }}
                            options={[
                                { label: str(`YES`), value: 'true' },
                                { label: str(`NO`), value: 'false' },
                            ]}
                        />
                    ))
                }

                <Group justify='flex-end'>
                    <Button variant="light" onClick={() => setOpenFilters(null)}>{str("CANCEL")}</Button>
                    <Button onClick={() => {
                        setPage(0);
                        setFilters(openFilters);
                        setOpenFilters(null);
                    }}>{str("SAVE")}</Button>
                </Group>
            </Group>
        </Modal>
    </Box>
}