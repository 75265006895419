import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { useLanguage } from "../contexts/language.context";
import { parseKeyword, useQuery } from "../utility/util";
import { ActionIcon, Badge, Box, Button, Divider, Grid, Group, Menu, Modal, Paper, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import Table from "../components/table";
import { GET_ALL_AGENTS, GET_ALL_TEMPLATES, SAVE_AGENT } from "../services/agents";
import { notifications } from "@mantine/notifications";
import SimpleHeader from "../components/simple_header";
import { FaArrowLeft, FaCopy, FaEllipsisV, FaPlus, FaSearch, FaWrench } from "react-icons/fa";
import InputField from "../components/input_field";
import Agent from "./agent";
  
export default function Home(){
    const [agents, setAgents] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [newAgent, setNewAgent] = useState<any>(null);
    const [filters, setFilters] = useState<any>({
        keyword: "",
    });
    const theme = useMantineTheme();
    
    const navigate = useNavigate();
    const { role, user: currentUser, userData } = useAuth();
    const { str } = useLanguage();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const query = useQuery();
    const service = query.get("service");
    const { mobileApp } = useApp();

    const renderAgent = (agent, options : any = {}) => (
        <Paper shadow='xs' style={{padding: 0}}>
            <Box p="lg">
                <Text c="gray.0" size="xl" fw="bold">{agent.title}</Text>
                {/* {agent.description && <Text c="gray.1" mt="xs" size="md">{agent.description}</Text>} */}
            </Box>
            <Group p="md">
                <Badge variant="light" color="pink">{agent.language ?? "en-US"}</Badge>
                <Badge variant="light" color="blue">{agent.role ?? "Other"}</Badge>
            </Group>
            <Divider color="dark.8" />
            <Group>
                {options.button ?? <Button m="xs" style={{flex: 1}} variant="transparent" color="gray.0"
                    onClick={() => navigate(`/agents/${agent._id}`)}
                    leftSection={<FaWrench />}
                >{str("EDIT")}</Button>}
            </Group>
        </Paper>
    )

    const loadAgents = () => {
        GET_ALL_AGENTS()
        .then(agents => {
            setAgents(agents);
            // if(!agentId && agents[0]) navigate(`/agents/${agents[0]._id}`)
        })
        .catch(err => {
            notifications.show({ message: err.message, color: 'red' })
        })
    }
    
    const loadTemplateAgents = () => {
        GET_ALL_TEMPLATES()
        .then(agents => {
            setTemplates(agents);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: 'red' })
        })
    }

    const handleSave = () => {
        SAVE_AGENT(newAgent)
        .then((agent) => {
            loadAgents();
            setNewAgent(null);
            navigate(`/agents/${agent._id}`)
        })
        .catch(err => {
            notifications.show({ message: err.message, color: 'red' })
        })
    }

    useEffect(() => {
        loadAgents()
        loadTemplateAgents()
    }, [])

    return <div>      
        <SimpleHeader
            right={<Group>
                <InputField
                    placeholder={str("AGENTS.SEARCH_AGENT")}
                    name="keyword"
                    size="sm"
                    leftSection={<FaSearch />}
                    value={filters.keyword}
                    onChange={({keyword}) => setFilters(f => ({ ...f, keyword }))}
                />
            </Group>}
            buttons={[
                {rightSection: <FaPlus />, title: str("AGENTS.NEW_AGENT"), onClick: () => { setNewAgent({ })}},
            ]}
        />

        <Grid>
            {agents.filter(a => parseKeyword(a.title).includes(parseKeyword(filters.keyword))).map(a => (
                <Grid.Col span={{ base: 12, md: 4 }}>
                    {renderAgent(a)}
                </Grid.Col>
            ))}
        </Grid>

        <Modal
            opened={!!newAgent}
            onClose={() => setNewAgent(null)}
            size="md"
            title={<ActionIcon variant="transparent" onClick={() => {
                setNewAgent(na => (
                    (na?.creating_type === "scratch" && newAgent?.template_id)
                    ? { ...na, template_id: undefined }
                    : na?.creating_type
                    ? { ...na, creating_type: null }
                    : null
                ))
            }}>
                <FaArrowLeft />
            </ActionIcon>}
        >   
            {newAgent?.creating_type
            ? (newAgent?.creating_type === "template" && !newAgent?.template_id)
            ? <Group style={{flexDirection: 'column', alignItems: 'unset'}}>
                {[...templates, ...agents].map(a => renderAgent(a, {
                    backgroundColor: '#242442',
                    button: (
                        <Button m="xs" style={{flex: 1}} variant="transparent" color="white"
                            onClick={() => setNewAgent(na => ({...na, template_id: a._id}))}
                        >{str("SELECT")}</Button>
                    )
                }))}
            </Group>
            : <>
                <InputField
                    name="title"
                    title={str("AGENTS.AGENT_NAME")}
                    mb="md"
                    value={newAgent?.title}
                    onChange={({title}) => setNewAgent(na => ({...na, title}))}
                />
                
                <Group justify="flex-end">
                    <Button style={{flex: 1}} fullWidth onClick={handleSave}>{str("SAVE")}</Button>
                </Group>
            </>
            : <>
                <UnstyledButton
                    onClick={() => setNewAgent(na => ({...na, creating_type: "scratch"}))}
                    style={{width: '100%'}} mb="xs">
                    <Paper p="md" style={{backgroundColor: '#242442'}}>
                        <Text ta="center" size="xl">{str("AGENTS.FROM_SCRATCH")}</Text>
                    </Paper>
                </UnstyledButton>
                
                <UnstyledButton
                    onClick={() => setNewAgent(na => ({...na, creating_type: "template"}))}
                    style={{width: '100%'}} mb="xs">
                    <Paper p="md" style={{backgroundColor: '#242442'}}>
                        <Text ta="center" size="xl">{str("AGENTS.USE_TEMPLATE")}</Text>
                    </Paper>
                </UnstyledButton>
            </>}
        </Modal>
    </div>
}
