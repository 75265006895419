import { ActionIcon, Button, Group, LoadingOverlay, Modal, Paper, Switch, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import Table from "../components/table";
import { useApp } from "../contexts/app.context";
import { useLanguage } from "../contexts/language.context";
import { GET_ALL_AGENTS } from "../services/agents";
import { DELETE_CAMPAIGN, GET_ALL_CAMPAIGNS, SAVE_CAMPAIGN, UPDATE_CAMPAIGN } from "../services/campaigns";
import { GET_ALL_CONTACTS_LIST } from "../services/contacts";
import { GET_ALL_PHONES } from "../services/phones";
import { formatMoney, timezones } from "../utility/util";

export default function CampaignsPage() {
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [lists, setLists] = useState<any[]>([]);
    const [agents, setAgents] = useState([]);
    const [variables, setVariables] = useState([]);
    const [phones, setPhones] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>({
        status: "all",
    });

    const navigate = useNavigate();

    const { confirmDialog } = useApp();
    const { str } = useLanguage();

    const loadPhones = () => {
        GET_ALL_PHONES()
            .then((res) => {
                setPhones(res)
            })
            .catch(err => {
                notifications.show({ title: "Ops.", message: err.message, color: 'red' })
            })
    }
    const loadCampaigns = () => {
        setLoading(true)
        GET_ALL_CAMPAIGNS()
            .then(res => {
                setLoading(false)
                setCampaigns(res.filter(c => c.status !== -1));
            })
            .catch(err => {
                setLoading(false)
                notifications.show({ message: err.message, color: "red" })
            })
    }

    const loadAgents = () => {
        GET_ALL_AGENTS()
            .then(setAgents)
            .catch(err => {
                notifications.show({ message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        let params = { ...selectedCampaign };

        setLoadingSave(true)
        SAVE_CAMPAIGN({ ...params })
            .then((res) => {
                notifications.show({ message: "Item saved!", color: "green" })
                loadCampaigns();
                setSelectedCampaign(null)
                setLoadingSave(false);
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
                setLoadingSave(false);
            })
    }

    const loadContactLists = () => {
        GET_ALL_CONTACTS_LIST()
            .then((items) => {
                setLists(items);
            })
            .catch((err) => { });
    };

    useEffect(loadCampaigns, []);
    useEffect(loadPhones, []);
    useEffect(loadContactLists, []);
    useEffect(loadAgents, []);
    useEffect(() => {
        const vs = [];
        const agent = agents.find(a => a._id === selectedCampaign?.agent);
        if (agent) {
            [
                ...(agent?.data?.instructions ?? "").match(/(\{\{)(.*?)(\}\})/g) ?? [],
                ...(agent?.data?.initial_message ?? "").match(/(\{\{)(.*?)(\}\})/g) ?? [],
            ].forEach((ks) => {
                const k = ks.replace(/\{\{|\}\}/g, "");
                if (!vs.includes(k)) vs.push(k);
            })
            setVariables(vs.filter(k => ![
                "contact_name",
                "contact_phone",
                "contact_email",
                "CurrentTime",
                "Contact.Name",
                "Contact.FirstName",
                "Contact.Phone",
                "Contact.Email",
            ].includes(k)))
        }
    }, [selectedCampaign?.agent, agents]);

    return <div style={{ position: 'relative' }}>
        {<LoadingOverlay visible={loading} />}
        <Paper mb="xl" p="md">
            <Group justify="flex-end">
                <InputField
                    name="status"
                    fieldType={InputFieldTypes.SELECT}
                    value={filters.status}
                    onChange={({ status }) => setFilters(f => ({ ...f, status }))}
                    options={[
                        { label: str("ALL"), value: "all" },
                        { label: str("ACTIVE"), value: "active" },
                        { label: str("INACTIVE"), value: "inactive" },
                    ]}
                />
                <Button
                    leftSection={<FaPlus />}
                    onClick={() => setSelectedCampaign({
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        periods: [
                            { start: "09:00", finish: "12:00", weekdays: [1, 2, 3, 4, 5] },
                            { start: "14:00", finish: "18:00", weekdays: [1, 2, 3, 4, 5] },
                        ]
                    })}
                >
                    {str("CAMPAIGNS.NEW")}
                </Button>
            </Group>
        </Paper>

        <Table
            data={campaigns
                .filter(c => (
                    filters.status === "all" ||
                    (filters.status === "active" && c.status === 1) ||
                    (filters.status === "inactive" && c.status !== 1)
                ))
            }
            // styles={{
            //     td: { borderRight: '1px solid #7C7CAC', borderBottom: '1px solid #7C7CAC' },
            //     th: { borderRight: '1px solid #7C7CAC', borderBottom: '1px solid #7C7CAC', padding: '20px 40px' },
            // }}
            columns={[
                {
                    title: str("OFF_ON"), style: { width: 50 }, key: "status", render: (item) => <Switch
                        checked={item.status === 1}
                        onChange={() => {
                            const status = item.status === 1 ? 0 : 1;
                            confirmDialog({
                                text: item.status === 1
                                    ? str("CAMPAIGNS.STOP_CONFIRM")
                                    : str("CAMPAIGNS.START_CONFIRM")
                            }, ({ confirmed }) => {
                                if (confirmed) {
                                    setCampaigns(c => c.map(it => it._id === item._id ? { ...it, status } : it))
                                    UPDATE_CAMPAIGN(item._id, { status })
                                        .then(() => { })
                                        .catch(() => { })
                                }
                            })
                        }}
                    />
                },
                { title: str("CAMPAIGNS.TITLE"), key: "title" },
                { title: str("TYPE"), key: "type", style: { minWidth: 80 }, render: (item) => item.type === "inbound" ? "Inbound" : "Outbound" },
                { title: str("AGENTS.TITLE"), key: "agent", style: { minWidth: 80 }, render: (item) => item?.agent?.title },
                { title: str("LISTS"), key: "lists", style: { minWidth: 80 }, render: (item) => (item?.lists ?? []).map(l => l.title).join(",") },
                { title: str("PHONE"), key: "phone", render: item => item?.phone?.number },
                { title: str("CALLS.DIALS"), key: "dials", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.dials ?? 0 },
                { title: str("CALLS.STATUS.PICKUP"), key: "pickups", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.pickups ?? 0 },
                { title: str("CALLS.STATUS.VOICEMAIL"), key: "voicemails", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.voicemails ?? 0 },
                { title: str("CALLS.STATUS.FAILED"), key: "failed", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.failed ?? 0 },
                { title: str("CALLS.STATUS.BUSY"), key: "busy", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.busy ?? 0 },
                { title: str("CALLS.STATUS.NO-ANSWER"), key: "no_answer", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.no_answer ?? 0 },
                { title: str("RESULTS"), key: "outcomes", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => item.totals?.outcomes ?? 0 },
                { title: str("SPENT"), key: "spent", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => `$${(item.totals?.spent ?? 0).toFixed(2)}` },
                { title: str("COST_PER_RESULT"), key: "cac", itemStyle: { textAlign: 'center' }, style: { minWidth: 80 }, render: (item) => `$${(item.totals?.cac ?? 0).toFixed(2)}` },
                {
                    title: str("OTHERS"), key: "dynamic", style: { minWidth: 200 }, render: (item) => {
                        return [
                            <Text size="xs">{str("CAMPAIGNS.MAX_DAILY_ATTEMPTS")}: {item.maxTries ?? 0}</Text>,
                            <Text size="xs">{str("CAMPAIGNS.ATTEMPTS_DELAY")}: {item.periodTries ?? 120} min</Text>,
                            <Text size="xs">{str("CAMPAIGNS.DAILY_BUDGET")}: {formatMoney(item.dailyLimit ?? 10)}</Text>,
                        ];
                    }
                },
                {
                    title: str("DYNAMIC_VALUES"), key: "dynamic", style: { minWidth: 200 }, render: (item) => {
                        let vls = [];
                        for (let key in item.dynamic) {
                            vls.push(<Text size="xs">{key}: {item.dynamic[key]}</Text>)
                        }
                        return vls;
                    }
                },
            ]}
            actions={[
                { title: str("CALLS.SEE"), onClick: ({ item }) => { navigate(`/calls?campaign=${item._id}`) } },
                {
                    title: str("EDIT"), onClick: ({ item }) => {
                        setSelectedCampaign({
                            ...item,
                            agent: item.agent?._id,
                            lists: (item.lists ?? []).map(l => l._id),
                        })
                    }
                },
                {
                    title: str("CLONE"), onClick: ({ item }) => {
                        setSelectedCampaign({
                            ...item,
                            _id: undefined,
                            lists: (item.lists ?? []).map(l => l._id),
                        })
                    }
                },
                {
                    title: str("DELETE"), onClick: ({ item }) => {
                        confirmDialog({ text: str("CAMPAIGNS.DELETE_CONFIRM") }, ({ confirmed }) => {
                            if (confirmed) {
                                setCampaigns(c => c.filter(it => it._id !== item._id))
                                DELETE_CAMPAIGN(item._id)
                                    .then(() => { })
                                    .catch(() => { })
                            }
                        })
                    }
                },
            ]}
        />

        <Modal
            opened={!!selectedCampaign}
            onClose={() => setSelectedCampaign(null)}
            size="xl"
        >

            <InputField
                name="title"
                onChange={({ title }) => setSelectedCampaign(sp => ({ ...sp, title }))}
                value={selectedCampaign?.title}
                title="Campaign Title"
            />

            <InputField
                name="type"
                mt="md"
                onChange={({ type }) => setSelectedCampaign(sp => ({ ...sp, type, phone: undefined }))}
                value={selectedCampaign?.type ?? "outbound"}
                title="Type"
                options={[
                    { label: str("CALLS.OUTBOUND"), value: 'outbound' },
                    { label: str("CALLS.INBOUND"), value: 'inbound' },
                ]}
                fieldType={InputFieldTypes.BOX}
            />
            <Group mt="md">
                <InputField
                    fieldType={InputFieldTypes.SELECT}
                    name="phone"
                    value={selectedCampaign?.phone?._id ?? selectedCampaign?.phone}
                    style={{ flex: 1 }}
                    title={str("PHONE")}
                    onChange={({ phone }) => setSelectedCampaign(sp => ({ ...sp, phone }))}
                    options={phones
                        .filter(p =>
                            p.type === "sip" ||
                            (
                                ((selectedCampaign?.type ?? "outbound") === "outbound" || ["new"].includes(p?.type)) &&
                                ["in-use", "success"].includes(p?.data?.twilio?.status) ||
                                p?.data?.telnyx?.status
                            )
                        )
                        .map((l) => ({ value: l._id, label: l.number }))}
                />

                <InputField
                    fieldType={InputFieldTypes.SELECT}
                    name="agent"
                    value={selectedCampaign?.agent}
                    style={{ flex: 1 }}
                    title={str("AGENTS.TITLE")}
                    onChange={({ agent }) => setSelectedCampaign(sp => ({ ...sp, agent }))}
                    options={agents.map((l) => ({ value: l._id, label: l.title }))}
                />

                {selectedCampaign?.type === "outbound" && <InputField
                    fieldType={InputFieldTypes.SELECT}
                    name="lists"
                    value={(selectedCampaign?.lists ?? [])}
                    style={{ flex: 1 }}
                    title={str("CONTACTS.LISTS")}
                    multiple
                    onChange={({ lists }) => setSelectedCampaign(sp => ({ ...sp, lists }))}
                    options={lists.filter(list => list._id !== "unsigned").map((l) => ({ value: l._id, label: l.title }))}
                />}
            </Group>
            <Group mt="md">
                <InputField
                    name="maxTries"
                    value={selectedCampaign?.maxTries ?? 0}
                    fieldType={InputFieldTypes.NUMBER}
                    style={{ flex: 1 }}
                    title={str("CAMPAIGNS.MAX_DAILY_ATTEMPTS")}
                    description={str("CAMPAIGNS.MAX_DAILY_ATTEMPTS_SUBTITLE")}
                    onChange={({ maxTries }) => setSelectedCampaign(sp => ({ ...sp, maxTries }))}
                />

                <InputField
                    name="periodTries"
                    value={selectedCampaign?.periodTries ?? 120}
                    fieldType={InputFieldTypes.NUMBER}
                    style={{ flex: 1 }}
                    title={str("CAMPAIGNS.ATTEMPTS_DELAY")}
                    description={str("CAMPAIGNS.ATTEMPTS_DELAY_SUBTITLE")}
                    error={(selectedCampaign?.periodTries ?? 120) < 30 ? "Min 30" : ""}
                    rightSection={<Text>{str("MINUTES_ABREV").toLocaleLowerCase()}</Text>}
                    onChange={({ periodTries }) => {
                        setSelectedCampaign(sp => ({ ...sp, periodTries }))
                    }}
                />

                <InputField
                    name="dailyLimit"
                    value={selectedCampaign?.dailyLimit ?? 10}
                    style={{ flex: 1 }}
                    title={str("CAMPAIGNS.DAILY_BUDGET")}
                    description={str("CAMPAIGNS.DAILY_BUDGET_SUBTITLE")}
                    error={(selectedCampaign?.dailyLimit ?? 10) < 10 ? "Min 10" : ""}
                    onChange={({ dailyLimit }) => { setSelectedCampaign(sp => sp ? ({ ...sp, dailyLimit }) : sp) }}
                    mask={InputFieldMasks.MONEY}
                />
            </Group>

            {
                (variables ?? []).map(v => (
                    <InputField
                        title={v}
                        value={{ ...selectedCampaign?.dynamic }[v]}
                        name="vl"
                        mt="md"
                        onChange={({ vl }) => setSelectedCampaign(nc => ({ ...nc, dynamic: { ...nc.dynamic, [v]: vl } }))}
                    />
                ))
            }

            {(selectedCampaign?.type ?? "outbound") === "outbound" && <div>
                <InputField
                    title={str("TIMEZONE")}
                    value={selectedCampaign?.timezone}
                    name="timezone"
                    mt="md"
                    fieldType={InputFieldTypes.SELECT}
                    onChange={({ timezone }) => setSelectedCampaign(nc => ({ ...nc, timezone }))}
                    options={timezones.reduce((pv, tz) => {
                        return pv.concat(tz.utc.map(item => ({ value: item, label: `${item} ${tz.text}` })))
                    }, [])}
                />
                <Group mt="md">
                    <Text fw="bold" style={{ flex: 1 }}>{str("PERIODS")}</Text>
                    <ActionIcon
                        onClick={() => {
                            setSelectedCampaign(sc => ({ ...sc, periods: [...(sc?.periods ?? []), {}] }))
                        }}
                    ><FaPlus /></ActionIcon>
                </Group>
                {(selectedCampaign?.periods ?? []).map((p, pi) => <>
                    <Group align="flex-end" mt="md">
                        <InputField
                            name="start"
                            title={str("START")}
                            fieldType={InputFieldTypes.TIME}
                            value={p?.start}
                            style={{ flex: 1 }}
                            onChange={({ start }) => {
                                setSelectedCampaign(sc => ({ ...sc, periods: sc.periods.map((p2, pj) => pi === pj ? { ...p2, start } : p2) }))
                            }}
                        />
                        <InputField
                            name="finish"
                            title={str("FINISH")}
                            fieldType={InputFieldTypes.TIME}
                            value={p?.finish}
                            style={{ flex: 1 }}
                            onChange={({ finish }) => {
                                setSelectedCampaign(sc => ({ ...sc, periods: sc.periods.map((p2, pj) => pi === pj ? { ...p2, finish } : p2) }))
                            }}
                        />
                        <ActionIcon size="lg" variant="outline" onClick={() => {
                            setSelectedCampaign(sc => ({ ...sc, periods: sc.periods.filter((p2, pj) => pi !== pj) }))
                        }}><FaTrash /></ActionIcon>
                    </Group>
                    <Group justify="center" mt="xs">
                        {Array(7).fill({}).map((wd, wdi) =>
                            <Button
                                onClick={() => {
                                    setSelectedCampaign(d => ({
                                        ...d, periods: d.periods.map((p2, pj) => pi === pj ? {
                                            ...p2,
                                            weekdays: (p2.weekdays || []).includes(wdi)
                                                ? (p2.weekdays || []).filter(it => it !== wdi)
                                                : [...(p2.weekdays || []), wdi]
                                        } : p2)
                                    }))
                                }}
                                variant={(p.weekdays || []).includes(wdi) ? 'filled' : 'outline'}>
                                {str(`WEEKDAYS.${wdi}`)[0]}
                            </Button>
                        )}
                    </Group>
                </>)}
            </div>}

            <Group justify="center" mt="md">
                <Button
                    disabled={
                        (selectedCampaign?.periodTries ?? 30) < 30 ||
                        (selectedCampaign?.dailyLimit ?? 10) < 10
                    }
                    size="sm" onClick={handleSave} loading={loadingSave} color="green">{str("CAMPAIGNS.SAVE")}</Button>
            </Group>
            {/* <Text ta="center" size="xs" mt="md">Telephony costs billed separately per <a href="https://www.twilio.com/en-us/pricing" target="_blank">Twilio's pricing</a></Text> */}
        </Modal>
    </div>
}
