import {
  ActionIcon,
    Badge,
    Box,
    Button,
    Divider,
    Group,
    Menu,
    Modal,
    Paper,
    Text,
    useMantineTheme
} from "@mantine/core";
import React, { useState } from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaEdit, FaEllipsisV, FaPlus, FaTags, FaTrash, FaWrench } from "react-icons/fa";
import { timezones } from "../../utility/util";
import { useApp } from "../../contexts/app.context";
import { SAVE_TAG } from "../../services/tags";
import { showNotification } from "@mantine/notifications";

export default function OthersPrompt({ data, setData, loadTags, tags = [] }: any){
    const { str } = useLanguage();
    const { confirmDialog } = useApp();
    const theme = useMantineTheme();

    const [updatingTag, setUpdatingTag] = useState<any>(null);
    const [newTag, setNewTag] = useState<any>(null);

    return <>
      <Group mb="md">
        <FaTags />
        <Text size="lg" fw="bold" style={{flex: 1}} c="gray.0">Tags</Text>
        <Button
          onClick={() => { setUpdatingTag({}) }}
          leftSection={<FaPlus />}
          size="sm"
          variant="outline"
          color="white"
        >Add Tag</Button>
      </Group>

      {(data?.data?.tags ?? []).length === 0 && <Box mt="80px" mb="80px">
        <Text mb="xs" fw="bold" c="white" size="lg" ta="center">{str("AGENTS.NO_TAG_TITLE")}</Text>
        <Text c="white" size="sm" ta="center">{str("AGENTS.NO_TAG_SUBTITLE")}</Text>
      </Box>}
      
      {(data?.data?.tags ?? []).map((tag, ai) => <Paper p="md" mt="md" style={{backgroundColor: theme.colors.dark[8]}}>
        <Group mb="xs">
          <Badge color="violet" fw="bold" size="lg">{tags.find(t => t._id === tag.tag_id)?.title ?? "Tag"}</Badge>
          <div style={{flex: 1}}></div>
          <Menu>
            <Menu.Target>
              <ActionIcon color="white" variant="transparent"><FaEllipsisV /></ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => setUpdatingTag({ index: ai, tag })}
                leftSection={<FaEdit />}
              >{str("UPDATE")}</Menu.Item>
              <Menu.Item
                leftSection={<FaTrash />}
                onClick={() => {
                  confirmDialog({ text: str("AGENTS.CONFIRM_REMOVE_ACTION") }, ({ confirmed }) => {
                    if(confirmed){
                      setData(na => ({ ...na, data: { ...na?.data, tags: na.data?.tags.filter((v2, aj) => ai !== aj) } }))
                    }
                  })
                }}
              >{str("REMOVE")}</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
        {tag?.add && <Text size="sm">Add when: {tag?.add}</Text>}
        {tag?.remove && <Text size="sm">Remove when: {tag?.remove}</Text>}
      </Paper>)}

      <Modal
        opened={!!updatingTag}
        onClose={() => setUpdatingTag(null)}
        size="sm"
      >
        <InputField
          name="tag_id"
          fieldType={InputFieldTypes.SELECT}
          value={updatingTag?.tag?.tag_id}
          onChange={({tag_id}) => {
            if(tag_id === "new-tag") setNewTag({  });
            else setUpdatingTag(ut => ({ ...ut, tag: {...ut?.tag, tag_id }}));
          }}
          options={[
            ...tags.map(tg => ({ label: tg.title, value: tg._id, group: tg?.category?.title ?? "Others" })),
            { value: "new-tag", label: "New Tag" },
          ]}
          mb="md"
        />
        <InputField
          name="add"
          value={updatingTag?.tag?.add}
          onChange={({add}) => {
            setUpdatingTag(ut => ({ ...ut, tag: {...ut?.tag, add }}))
          }}
          title="Add Tag to Contact When"
          placeholder="Describe when should add this tag"
          mb="md"
        />
        <InputField
          name="remove"
          value={updatingTag?.tag?.remove}
          onChange={({remove}) => {
            setUpdatingTag(ut => ({ ...ut, tag: {...ut?.tag, remove }}))
          }}
          title="Remove Tag from Contact When"
          placeholder="Describe when should remove this tag"
          mb="md"
        />
        <Group justify="flex-end">
          <Button
            onClick={() => {
              setData(na => ({ ...na, data: { ...na?.data, tags: (
                ![null, undefined].includes(updatingTag?.index)
                ? (na.data?.tags ?? []).map((v2, aj) => updatingTag?.index === aj ? { ...v2, ...updatingTag?.tag } : v2)
                : [...(na.data?.tags ?? []), updatingTag?.tag]
              ) } }));
              setUpdatingTag(null);
            }}
          >{str("SAVE")}</Button>
        </Group>
      </Modal>

      <Modal
        opened={!!newTag}
        onClose={() => setNewTag(null)}
      >
        <InputField
          name="title"
          placeholder={str("TITLE")}
          value={newTag?.title}
          onChange={({title}) => { setNewTag(nc => ({ ...nc, title })) }}
        />
        <Button
          mt="md"
          onClick={() => {
            SAVE_TAG({ ...newTag })
            .then(res => {
              setData(d => ({ ...d, calendar: res._id }));
              setNewTag(null);
              loadTags && loadTags();
            })
            .catch(err => {
              showNotification(err);
            })
          }}
        >{str("SAVE")}</Button>
      </Modal>
    </>
}