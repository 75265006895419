import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Switch,
  Text,
  Title,
  UnstyledButton
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaPlay,
  FaTimes
} from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import InputField, { InputFieldTypes } from "../components/input_field";
import AudioPlayer from "../components/audio_player";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import {
  DELETE_CONTACT,
  GET_ALL_CONTACTS_LIST,
  GET_CONTACT,
  UPDATE_CONTACT,
} from "../services/contacts";
import { formatMilliseconds, getExtenseDatetime } from "../utility/util";
import { GET_ALL_TAGS } from "../services/tags";
import { useLanguage } from "../contexts/language.context";

export default function Home() {
  const { id: contactId } = useParams();
  const { user: currentUser, userData } = useAuth();
  const { role } = useAuth();

  const navigate = useNavigate();

  const app = useApp();
  const { str } = useLanguage();

  const utf8 = require("utf8");

  const [dataChannel, setDataChannel] = useState<{ [key: string]: any }>({});
  const [data, setData] = useState<any>(null);
  const [selectedLists, setSelectedLists] = useState<any>([]);
  const [newConversation, setNewConversation] = useState<any>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [variables, setVariables] = useState<any>({ props: { extras: [] } });
  const [extras, setExtras] = useState<any[]>([]);
  const [lists, setLists] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [tagsCategories, setTagsCategories] = useState<any[]>([]);
  const [identifiers, setIdentifiers] = useState<any[]>([]);
  const [contactName, setContactName] = useState("");
  const [loadingDelete, setLoadingDelete] = React.useState({});

  const loadData = (setLoading = true) => {
    if (contactId) {
      setLoadingData(setLoading);
      GET_CONTACT(contactId)
        .then((dt) => {
          setLoadingData(false);
          setDataChannel((prev) => ({ ...prev, [`${contactId}`]: dt }));
          setVariables((s) => ({ ...s, props: dt }));
          setExtras(dt.extras ?? []);
        })
        .catch((err) => {
          setLoadingData(false);
          showNotification({
            title: "Ops",
            message: err.message,
            color: "red",
          });
        });
    }
  };

  const loadContactLists = () => {
    GET_ALL_CONTACTS_LIST()
      .then((items) => {
        setLists(items);
      })
      .catch((err) => {});
  };

  const loadTags = () => {
    GET_ALL_TAGS()
      .then(({tags = [], categories = []}) => {
        setTags(tags);
        setTagsCategories(categories);
      })
      .catch((err) => {});
  };

  const removeList = (listId) => {
    setSelectedLists((ls) => ls.filter((l) => l.id !== listId));
  };

  const addList = (listId) => {
    const list = lists.find((l) => l._id === listId);
    setSelectedLists((ls) => [
      ...(ls || []),
      { id: list._id, title: list.title },
    ]);
  };

  const handleChangeVariables = (dt) => {
    setVariables((s) => ({ ...s, ...dt }));
  };

  const handleDeleteVariable = (index) => {
    setVariables((s) => ({
      ...s,
      props: {
        ...s.props,
        extras: s.props.extras.filter((_, i) => i !== index),
      },
    }));
  };

  const deleteContact = (id) => {
    setLoadingDelete((d) => ({ ...d, [id]: true }));

    DELETE_CONTACT(id)
      .then(() => {
        setLoadingDelete((d) => ({ ...d, [id]: false }));
        navigate("/contatos");
        showNotification({
          title: "Sucesso",
          message: "Contato deletado com sucesso",
          color: "green",
        });
      })
      .catch((err) => {
        setLoadingDelete((d) => ({ ...d, [id]: false }));
        alert(err.message);
      });
  };

  const handleSaveContact = () => {
    if (contactId) {
      setLoadingSave(true);
      const { props } = variables;
      const params = {
        extras,
        lists: selectedLists,
        identifiers,
        name: contactName,
        tags: (data.tags ?? []),
        unsubscribe: data?.unsubscribe,
      };

      UPDATE_CONTACT(params, contactId)
        .then((dt) => {
          setLoadingSave(false);
          showNotification({
            message: "Contact updated!",
            color: "green",
          });
        })
        .catch((err) => {
          setLoadingSave(false);
          showNotification({
            message: err.message,
            color: "red",
          });
        });
    }
  };

  useEffect(() => {
    loadData();
    loadContactLists();
    loadTags();
  }, [contactId]);

  useEffect(() => {
    if (contactId) {
      setData(dataChannel[`${contactId}`]);
    } else {
      setData({});
    }
  }, [contactId, dataChannel]);

  const transformIdentifierData = (identifiers: any) => {
    let newIdentifiers = Object.assign([], identifiers);
    if (identifiers.length === 2 && identifiers[0].key === "phone") {
      return newIdentifiers;
    } else {
      if (newIdentifiers.length === 0) {
        newIdentifiers.push({ key: "phone", value: "" });
        newIdentifiers.push({ key: "email", value: "" });
      } else if (newIdentifiers[0].key === "email") {
        newIdentifiers.unshift({ key: "phone", value: "" });
      } else if (newIdentifiers[0].key === "phone") {
        newIdentifiers.push({ key: "email", value: "" });
      }
    }
    return newIdentifiers;
  };

  useEffect(() => {
    setContactName(data?.name || []);
    setSelectedLists(data?.lists || []);
    setIdentifiers(transformIdentifierData(data?.identifiers || []));
  }, [data]);

  return (
    <div>
      <LoadingOverlay visible={loadingData} />


      <Grid>
        <Grid.Col span={{base:12, md: 12}}>
          <Paper shadow="xs" p="xs">
            <Group>
              <Avatar />
              <Title c="gray.0" style={{ flex: 1 }} order={4}>{data?.name}</Title>

              {/* {role?.profile?.title === "Administrador" && (
                <Button
                  style={{ width: "6rem" }}
                  variant="outline"
                  onClick={() => {
                    app.confirmDialog(
                      {
                        text: "Realmente deseja deletar permanentemente esse contato?",
                      },
                      ({ confirmed }) => {
                        if (confirmed) deleteContact(data?.id);
                      }
                    );
                  }}
                >
                  Deletar
                </Button>
              )} */}
              <Button
                style={{ width: "6rem" }}
                mr={"sm"}
                onClick={handleSaveContact}
              >
                {str("UPDATE")}
              </Button>
              {/*<Button
                leftIcon={<FaPlus />}
                variant="outline"
                c="gray"
                onClick={() => setNewConversation({ contact_id: contactId })}
              >
                Abrir chamado
      </Button>*/}
            </Group>
          </Paper>

        </Grid.Col>
        <Grid.Col span={{base:12, md: 8}}>
          <Paper shadow="sm" p="sm">
            <Grid align="center">
              <Grid.Col span={12} mt="md">
                <InputField
                  name="n"
                  title={str("NAME")}
                  value={contactName}
                  size="md"
                  onChange={({ n }) => {
                    setContactName(n);
                  }}
                />
              </Grid.Col>

              {identifiers.map((ident, i) => (
                <Grid.Col span={{base:12, md: 6}}>
                  <InputField
                    name="value"
                    size="md"
                    title={ident.key === "phone" ? str("PHONE") : str("EMAIL") }
                    onChange={({ value }) =>
                      setIdentifiers((items) =>
                        items.map((ident2, j) =>
                          i === j ? { ...ident2, value } : ident2
                        )
                      )
                    }
                    value={ident.value}
                  />
                </Grid.Col>
              ))}

              <Grid.Col span={12}>
                <InputField
                  fieldType={InputFieldTypes.SELECT}
                  name="l"
                  value={selectedLists.filter(l => lists.map(l2 => l2._id).includes(l.id)).map(s => s.id)}
                  title={str("LISTS")}
                  size="md"
                  multiple
                  onChange={({ l }) => {
                    // addList(l)
                    setSelectedLists(l.map(it => {
                      let exist = lists.find(l2 => it === l2._id);
                      return { id: exist?._id, title: exist.title }
                    }).filter(it => it?.id));
                  }}
                  options={lists
                    .filter(l => l._id !== "unsigned")
                    .map((l) => ({ value: l._id, label: l.title }))}
                />
              </Grid.Col>
              
              {[
                tags.some(t => !t.category) && { _id: undefined, title: str("OTHERS") },
                ...tagsCategories,
              ].filter(nn => nn).map(tc => {
                const selfTags = (tags ?? []).filter(t => t.category === tc._id)
                return <Grid.Col key={`${tc._id}`} span={4}>
                  <InputField
                    name="tags"
                    value={(data?.tags ?? []).filter(t => selfTags.some(t2 => t2._id === t))}
                    onChange={({ tags }) => {
                      setData(f => {
                        let tgs = [...tags, ...f.tags.filter(t => !selfTags.map(t2 => t2._id).includes(t))]
                        return { ...f, tags: tgs }
                      })
                    }}
                    fieldType={InputFieldTypes.SELECT}
                    title={tc.title}
                    multiple
                    options={selfTags.map((l) => ({ value: l._id, label: l.title }))}
                  />
                </Grid.Col>
              })}

              {(userData?.company?.fields ?? []).map(field => {
                let nv = extras ?? [];
                const existentField = extras.find(ot => ot.title === field.title);
                return <Grid.Col span={12}>
                  <InputField
                    name={"vl"}
                    fullWidth
                    title={field.title}
                    size="md"
                    onChange={({ vl }) => {
                      if(existentField){
                        nv = nv.map(ot => ot.title === field.title ? { ...ot, value: vl } : ot)
                      }else{
                        nv = [...nv, { key: field.key ?? field.title, title: field.title, value: vl }]
                      }
                      setExtras(nv);
                    }}
                    value={existentField?.value}
                  />
                </Grid.Col>
              })}

              <Grid.Col span={12}>
                <Switch checked={(data?.unsubscribe ?? []).length > 0} label={str("CONTACTS.UNSUBSCRIBED")} mb="xs" onChange={() => setData(d => ({
                  ...d,
                  unsubscribe: (data?.unsubscribe ?? []).length > 0 ? [] : [{ reason: "", createdAt: moment().format() }]
                }))} />
                <InputField
                  name={"reason"}
                  fullWidth
                  title={str("CONTACTS.UNSUBSCRIBED_REASON")}
                  size="md"
                  disabled={!(data?.unsubscribe ?? [])[0]}
                  onChange={({ reason }) => {
                    setData(d => ({
                      ...d,
                      unsubscribe: [{ ...d.unsubscribe[0], reason }]
                    }))
                  }}
                  value={data?.unsubscribe[0]?.reason}
                />
              </Grid.Col>
              
              {/* <Grid.Col span={12}>
                <Title order={5} fw="bold" mb="sm">
                  Dados Extra
                </Title>
                <UpdateContactExtras
                  step={variables}
                  onChange={handleChangeVariables}
                  onDelete={handleDeleteVariable}
                />
              </Grid.Col> */}
            </Grid>
          </Paper>
        </Grid.Col>

        <Grid.Col span={{base:12, md: 4}}>
          <Paper p="md">
            <Title order={4} mb="md">{str("CALLS.TITLE")}</Title>
            {(data?.calls || []).length === 0 && (<Text mb="md" c="gray" size="md">{str("CALLS.NO_CALL")}</Text>)}
            {(data?.calls || []).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1).map((c) => <Paper mb="xs" p="xs" style={{backgroundColor: "#242442"}}>
              <Group>
                <Box>
                    <AudioPlayer url={c?.recording_url}>
                        <ActionIcon disabled={!c?.recording_url} variant="outline" color="gray" size="lg"><FaPlay /></ActionIcon>
                    </AudioPlayer>
                </Box>
                <Box style={{flex: 1}}><Text ta="center">{c.direction === "inbound" ? str("CALLS.INBOUND") : str("CALLS.OUTBOUND")}</Text></Box>
                <Box style={{flex: 1}}><Text ta="center">{c.campaign ? c.campaign.title : str("CALLS.ROLEPLAY")}</Text></Box>
                <Box style={{flex: 1}}>
                  <Text ta="center" size="md" c="gray">${(c?.total_price ?? 0).toFixed(2)}</Text>
                  <Text ta="center" size="md" c="gray">{formatMilliseconds((c?.duration ?? 0) * 1000)}</Text>
                </Box>
              </Group>
              <Group mt="sm">
                <Box style={{flex: 1}}><Text size="xs" c="gray" ta="left">{getExtenseDatetime(c?.createdAt)}</Text></Box>
                <Box><Badge color={{
                    "failed": "red",
                    "pickup": "blue",
                    "completed": "blue",
                    "outcome": "green",
                    "busy": "yellow",
                    "voicemail": "violet",
                    "no-answer": "yellow",
                }[c.status] ?? "gray"} variant="outline">{str(`CALLS.STATUS.${(c.status ?? "").toUpperCase()}`)}</Badge></Box>
                {/* <Box>
                    <ActionIcon onClick={() => setExpandedCall(c)}><FaListAlt /></ActionIcon>
                </Box> */}
              </Group>
            </Paper>)}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
