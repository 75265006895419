import { api } from "./api"

export const GET_ALL_PHONES = async () => {
    const { data } = await api.get(`/phones`)
    return data.phones || [];
}

export const GET_ALL_TWILIOS = async () => {
    const { data } = await api.get(`/phones/twilios`)
    return data.twilios || [];
}

export const CONNECT_TWILIO = async (params: any) => {
    const { data } = await api.post(`/phones/twilios`, params)
    return data;
}

export const GET_AVAILABLE_PHONES = async (params) => {
    const { data } = await api.get(`/phones/available`, { params })
    return data ?? [];
}

export const SAVE_PHONE = async (params) => {
    const { data } = await api.post(`/phones`, params)
    return data.item;
}

export const VERIFY_TELNYX_PHONE = async (phoneId, code) => {
    const { data } = await api.patch(`/phones/${phoneId}/telnyx/verify/${code}`)
    return data;
}

export const DELETE_PHONE = async (phoneId) => {
    const { data } = await api.delete(`/phones/${phoneId}`)
    return data;
}

export const UPDATE_PHONE = async (phoneId, params) => {
    const { data } = await api.patch(`/phones/${phoneId}`, params)
    return data;
}

export const GET_ALL_WHATSAPPS = async () => {
    const { data } = await api.get(`/phones/whatsapps`)
    return data.whatsapps || [];
}
export const SAVE_WHATSAPP = async (params) => {
    const { data } = await api.post(`/phones/whatsapps`, params)
    return data.item;
}
export const DELETE_WHATSAPP = async (whatsappId) => {
    const { data } = await api.delete(`/phones/whatsapps/${whatsappId}`)
    return data;
}