import { ActionIcon, Badge, Box, Button, Checkbox, Divider, Grid, Group, Loader, Menu, Modal, Paper, Text, Title, UnstyledButton, useMantineTheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { FaArrowLeft, FaCalendarAlt, FaCalendarCheck, FaCheckCircle, FaEdit, FaGoogle, FaPlus, FaSquare, FaTrash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import InputField, { InputFieldTypes } from '../components/input_field';
import SimpleHeader from '../components/simple_header';
import { default as useOauth,  OAUTH } from '../components/oauth';
import { useApp } from '../contexts/app.context';
import { useLanguage } from '../contexts/language.context';
import { DELETE_AGENT_SCHEDULE, DELETE_SCHEDULE, GET_ALL_AGENTS_SCHEDULES, GET_ALL_CALENDARS, GET_CALENDAR_SCHEDULES, SAVE_AGENT_SCHEDULE, SAVE_CALENDAR } from '../services/calendars';
import { getExtenseDatetime, medias, timezones } from '../utility/util';
import { GET_ALL_AGENTS } from '../services/agents';
import { GET_CONTACTS } from '../services/contacts';
import { GET_ALL_PHONES } from '../services/phones';
import { GET_ALL_OAUTHS } from '../services/oauths';
import { FaCheck, FaSquareCheck } from 'react-icons/fa6';
import { useMediaQuery } from '@mantine/hooks';

export default function ChannelsPage(){
    const isMobile = useMediaQuery('(max-width: 900px)');
    
    const [updating, setUpdating] = React.useState<any>(null);
    const [agentSchedule, setAgentSchedule] = React.useState<any>(null);
    const [openDisponibilities, setOpenDisponibilities] = React.useState<any>(null);
    const [openIndisponibilities, setOpenIndisponibilities] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [loadingSchedules, setLoadingSchedules] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(moment().toDate());
    const [calendars, setCalendars] = React.useState<any[]>([]);
    const [agents, setAgents] = React.useState<any[]>([]);
    const [agentsSchedules, setAgentsSchedules] = React.useState<any[]>([]);
    const [selectedCalendar, setSelectedCalendar] = React.useState<any>(null);
    const [schedules, setSchedules] = React.useState<any>({});
    const [openItem, setOpenItem] = React.useState<any>(null);
    const [phones, setPhones] = React.useState<any[]>([]);
    const [oauths, setOauths] = React.useState<any[]>([]);

    const navigate = useNavigate();
    const theme = useMantineTheme();

    const timezone = calendars.find(c => c._id === selectedCalendar)?.timezone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;

    const listRef = React.useRef<any>();
    const firstViewRef = React.useRef<boolean>(false);
    const app = useApp();
    const { str } = useLanguage();
    const { connect } = useOauth();

    const currentData = calendars.find(c => c._id === selectedCalendar);
    const currentSchedules = selectedCalendar === "agents"
        ? agentsSchedules
            .filter(as => moment(as.datetime).isBetween(
                moment(selectedDate).startOf("day"),
                moment(selectedDate).endOf("day")
            ))
            .map(item => ({ origin: "agent", item }))
        : ({...schedules[selectedCalendar]}[moment(selectedDate).format("YYYY-MM-DD")] ?? []);

    const loadData = () => {
        setLoading(true)
        GET_ALL_CALENDARS()
        .then(cs => {
            setLoading(false)
            setCalendars(cs)
        })
        .catch(err => {
            setLoading(false)
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const loadAgentsSchedules = () => {
        setLoadingSchedules(true);
        GET_ALL_AGENTS_SCHEDULES()
        .then(cs => {
            setLoadingSchedules(false);
            setAgentsSchedules(cs)
        })
        .catch(err => {
            setLoadingSchedules(false);
            notifications.show({message: err.message, color: "red"})
        })
    }

    const loadPhones = () => {
        GET_ALL_PHONES()
        .then((res) => {
            setPhones(res)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const loadAgents = () => {
        GET_ALL_AGENTS()
        .then(as => {
            setAgents(as)
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const loadSchedules = () => {
        if(selectedCalendar && selectedCalendar !== "agents"){
            setLoadingSchedules(true);
            GET_CALENDAR_SCHEDULES(selectedCalendar, moment(selectedDate).format())
            .then(res => {
                setLoadingSchedules(false);
                setSchedules(ss => ({
                    ...ss,
                    [selectedCalendar]: {
                        ...ss[selectedCalendar],
                        [moment(selectedDate).format("YYYY-MM-DD")]: res,
                    }
                }));
            })
            .catch(err => {
                setLoadingSchedules(false);
                notifications.show({message: err.message, color: "red"})
            })
        }
    }

    const handleSave = () => {
        setLoadingSave(true)
        
        SAVE_CALENDAR(updating)
        .then(res => {
            setLoadingSave(false);
            setUpdating(null);
            loadData();
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
            setLoadingSave(false)
        })
    }
    
    const handleSaveAgentSchedule = () => {
        setLoadingSave(true)
        
        SAVE_AGENT_SCHEDULE(agentSchedule)
        .then(res => {
            setLoadingSave(false);
            loadAgentsSchedules();
            setAgentSchedule(null);
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
            setLoadingSave(false)
        })
    }

    const deleteSchedule = (scheduleId) => {
        (
            selectedCalendar === "agents"
            ? DELETE_AGENT_SCHEDULE(scheduleId)
            : DELETE_SCHEDULE(scheduleId)
        )
        .then(res => {
            setOpenItem(null);
            loadData();
            if(selectedCalendar === "agents") loadAgentsSchedules();
            else loadSchedules();
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const handleSaveDisponibilities = () => {
        setLoadingSave(true)
        
        SAVE_CALENDAR({ id: selectedCalendar, disponibilities: openDisponibilities })
        .then(res => {
            setLoadingSave(false);
            setOpenDisponibilities(null);
            loadData();
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
            setLoadingSave(false)
        })
    }
    
    const handleSaveIndisponibilities = () => {
        setLoadingSave(true)
        
        SAVE_CALENDAR({ id: selectedCalendar, indisponibilities: openIndisponibilities })
        .then(res => {
            setLoadingSave(false);
            setOpenIndisponibilities(null);
            loadData();
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
            setLoadingSave(false)
        })
    }
    
    const loadOauths = () => {
        GET_ALL_OAUTHS()
        .then(res => {
            setOauths(res.filter(item => item.origin === "calendar"));
        })
        .catch(err => {
            notifications.show({message: err.message, color: "red"})
        })
    }

    useEffect(loadData, []);
    useEffect(() => {
        if(!firstViewRef.current){
            setSelectedCalendar(isMobile ? calendars[0]?._id : "agents");
            firstViewRef.current = true;
        }
    }, [isMobile, calendars]);
    useEffect(loadOauths, []);
    useEffect(loadAgentsSchedules, []);
    useEffect(loadSchedules, [selectedCalendar, selectedDate]);
    useEffect(loadAgents, []);
    useEffect(loadPhones, []);

    return <>
        <SimpleHeader variant="light" title={str("CALENDARS.TITLE")} Icon={loading ? Loader : FaCalendarAlt}
            right={
                <Group ml="md">
                    {selectedCalendar && selectedCalendar !== "agents" && <ActionIcon
                        size="md"
                        variant='transparent'
                        onClick={() => setUpdating({ ...calendars.find(c => c._id === selectedCalendar) })}
                    ><FaEdit /></ActionIcon>}
                    
                    {/* <InputField
                        name="tp"
                        value={selectedType}
                        fieldType={InputFieldTypes.SELECT}
                        onChange={({tp}) => setSelectedType(tp)}
                        clearable={false}
                        options={[
                            {label: "Agendamentos", value: "schedules"},
                            {label: "Disponibilidade", value: "disponibilities"},
                            {label: "Indisponibilidade", value: "indisponibilities"},
                        ]}
                    /> */}
                </Group>
            }
            button={{title: str("CALENDAR"), leftSection: <FaPlus />, onClick: () => {setUpdating({
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })}}}  />

        <Paper shadow='xs' style={{padding: 0}}>
            <Grid gutter={0}>
                {(!isMobile || !selectedCalendar) && <Grid.Col span={{ base: 12, md: 4 }} style={{minHeight: '70vh'}}>
                    <Box style={{height: '100%'}}>
                        {[
                            {
                                title: str("CALENDARS.AGENTS_CALENDAR"),
                                _id: "agents",
                            },
                            ...calendars,
                        ].map((calendar) => (
                            <UnstyledButton style={{width: '100%'}} onClick={() => {
                                // if(!allowed) notifications.show({
                                //     title: "Payment required",
                                //     message: "Check for your billing settings, there is one payment subscription that failed, change your payment method and try again.",
                                //     color: "yellow"
                                // })
                                setSelectedCalendar(calendar._id)
                            }}>
                                <Group
                                    style={{borderRight: `7px solid ${selectedCalendar === calendar._id ? "black" : "transparent"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text size="md" fw="bold" c="gray.0">{calendar?.title}</Text>
                                    </Box>
                                </Group>
                                <Divider />
                            </UnstyledButton>
                        ))}
                    </Box>
                </Grid.Col>}
                {(!isMobile || selectedCalendar) && <Grid.Col span={{ base: 12, md: 8 }} style={{minHeight: '70vh', backgroundColor: theme.colors.dark[9]}}>
                    {isMobile && <Group p="md">
                        <ActionIcon size="xl" onClick={() => setSelectedCalendar(null)}><FaArrowLeft /></ActionIcon>
                    </Group>}
                    <Box p="sm">
                        {!selectedCalendar
                        ? <Title mt="xl" order={5} ta='center'>{str("CALENDARS.CREATE_ONE")}</Title>
                        : <Group style={{ display: 'flex', flexDirection: 'row', alignItems: 'unset' }}>
                            <div>
                                <Paper ta="center" p="xs" style={{textAlign: 'center'}}>
                                    <Group justify="center">
                                        <DatePicker
                                            style={{textAlign: 'center'}}
                                            value={selectedDate}
                                            onChange={setSelectedDate}
                                        />
                                    </Group>

                                    {selectedCalendar === "agents"
                                    ? <Button
                                        fullWidth
                                        onClick={() => { setAgentSchedule({}) }}
                                        mt="md"
                                    >{str("CALENDARS.NEW_SCHEDULE")}</Button>
                                    : <Menu
                                        closeOnItemClick={false}
                                    >
                                        <Menu.Target>
                                            <Button
                                                variant='light'
                                                mt="md"
                                                leftSection={<FaPlus />}
                                            >{str("INTEGRATIONS.TITLE")} ({(currentData?.accounts ?? []).length})</Button>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {
                                                oauths.map((oauth) => {
                                                    return <>
                                                        <Menu.Label>
                                                            <Group>
                                                                <FaGoogle />
                                                                <Text size="xs">{oauth?.data?.me?.name} - {oauth?.data?.me?.email}</Text>
                                                            </Group>
                                                        </Menu.Label>

                                                        {
                                                            (oauth?.data?.accounts ?? []).map(account => {
                                                                const isChecked = (currentData?.accounts ?? []).some(c => c.oauth === oauth._id && c.account === account.id);
                                                                return <Menu.Item
                                                                    leftSection={
                                                                        <ActionIcon
                                                                            size="xs"
                                                                            color={
                                                                                !isChecked
                                                                                ? "gray"
                                                                                : "green"
                                                                            }
                                                                        >
                                                                            {
                                                                                isChecked
                                                                                && <FaCheck />
                                                                            }
                                                                        </ActionIcon>
                                                                    }
                                                                    onClick={() => {
                                                                        const accounts = [
                                                                            ...(currentData?.accounts ?? []).filter(acc => acc.oauth !== oauth._id || acc.account !== account.id),
                                                                            ...(isChecked ? [] : [{ oauth: oauth._id, account: account.id }])
                                                                        ];
                                                                        setCalendars(c => c.map(cal => (
                                                                            cal._id === selectedCalendar
                                                                            ? { ...cal, accounts }
                                                                            : cal
                                                                        )))
                                                                        SAVE_CALENDAR({ id: selectedCalendar, accounts })
                                                                        .then(res => {
                                                                            loadSchedules();
                                                                        })
                                                                        .catch(err => {
                                                                            notifications.show({message: err.message, color: "red"})
                                                                        })
                                                                    }}
                                                                >{account.title}</Menu.Item>
                                                            })
                                                        }
                                                    </>
                                                })
                                            }
                                            <Menu.Label>{str("ADD")}</Menu.Label>
                                            {
                                                medias
                                                .map(({ Icon, title, key, color }) => (
                                                    <Menu.Item
                                                        color={color}
                                                        leftSection={<Icon />}
                                                        onClick={() => {
                                                            connect(key as OAUTH, loadOauths);
                                                        }}
                                                    >{title}</Menu.Item>
                                                ))
                                            }
                                        </Menu.Dropdown>
                                    </Menu>}
                                    <br/>

                                    {selectedCalendar !== "agents" && <>
                                        <Button
                                            leftSection={<Text>({(currentData?.disponibilities ?? []).length})</Text>}
                                            variant='outline'
                                            mt="md"
                                            onClick={() => setOpenDisponibilities(currentData?.disponibilities || [])}
                                        >{str("CALENDARS.AVAILABILITIES")}</Button>
                                        <br/>
                                        <Button
                                            leftSection={<Text>({(currentData?.indisponibilities ?? []).length})</Text>}
                                            variant='outline'
                                            mt="md"
                                            onClick={() => setOpenIndisponibilities(currentData?.indisponibilities || [])}
                                        >{str("CALENDARS.UNAVAILABILITIES")}</Button>
                                    </>}
                                </Paper>
                            </div>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                <Text mb="md" fw="bold">{getExtenseDatetime(selectedDate, false)}</Text>
                                {
                                    currentSchedules.length === 0
                                    ? <Paper mb="md" p="xs">
                                        <Text size="sm" c="gray">{str("CALENDARS.NO_EVENT")}</Text>
                                    </Paper>
                                    : currentSchedules
                                    .map(({ origin, item: schedule }) => (
                                        origin === "calendar"
                                        ? <Paper mb="md" p="xs">
                                            <Group>
                                                <ActionIcon color="red" variant='light' size="md"><FaGoogle /></ActionIcon>
                                                <Box style={{flex: 1}}>
                                                    <Text size="sm" fw="bold">{schedule?.summary}</Text>
                                                </Box>
                                                <Box>
                                                    <Text ta="center" size="sm" c="gray">{moment(schedule?.start?.dateTime).tz(timezone).format("HH:mm")} - {moment(schedule?.end?.dateTime ?? schedule?.start?.dateTime).tz(timezone).format("HH:mm")}</Text>
                                                    <Text ta="center" size="xs" c="gray">{timezone}</Text>
                                                </Box>
                                            </Group>
                                        </Paper>
                                        : <Paper mb="md" p="xs">
                                            <Group>
                                                <ActionIcon color="gray" size="md"
                                                    onClick={() => setOpenItem(schedule)}
                                                ><FaCalendarCheck /></ActionIcon>
                                                <Box style={{flex: 1}}>
                                                    <Text size="sm" fw="bold">{schedule?.contact?.name}</Text>
                                                    {selectedCalendar === "agents" && <Text size="xs" c="gray">{str("AGENTS.TITLE")}: {schedule?.agent?.title}</Text>}
                                                </Box>
                                                <Box>
                                                    <Text ta="center" size="sm" c="gray">{moment(schedule?.datetime).tz(timezone).format("HH:mm")} - {moment(schedule?.datetime).tz(timezone).add(schedule?.duration ?? 30, "minutes").format("HH:mm")}</Text>
                                                    <Text ta="center" size="xs" c="gray">{timezone}</Text>
                                                </Box>
                                                {
                                                    (schedule?.data?.third ?? []).map(third => (
                                                        <Button
                                                            color="red"
                                                            variant='light'
                                                            fullWidth
                                                            leftSection={<FaGoogle />}
                                                            onClick={() => {
                                                                window.open(third?.response?.hangoutLink ?? third?.response?.htmlLink, "_blank")
                                                            }}
                                                        >{third?.response?.hangoutLink ?? third?.response?.htmlLink}</Button>
                                                    ))
                                                }
                                            </Group>
                                        </Paper>
                                    ))
                                }
                                {loadingSchedules && <Loader />}
                            </div>
                        </Group>}
                    </Box>

                </Grid.Col>}
            </Grid>
        </Paper>

        <Modal
            opened={!!openItem}
            onClose={() => setOpenItem(null)}
            title={openItem?.contact?.name}
        >
            <Text>{str("DATE")}: {moment(openItem?.datetime).format("DD/MM/YYYY")}</Text>
            <Text>{str("TIME")}: {moment(openItem?.datetime).format("HH:mm")}</Text>
            <Text>{str("CONTACT")}: <Link to={`/contacts/${openItem?.contact?._id}`}>{openItem?.contact?.name}</Link></Text>

            <Group mt="lg" justify='flex-end'>
                <Button color="red" onClick={() => {
                    app.confirmDialog({text: "Sure about deleting this schedule?", buttonText: "Delete"}, ({confirmed}) => {
                        if(confirmed) deleteSchedule(openItem?._id)
                    })
                }}>{str("CALENDARS.DELETE_SCHEDULE")}</Button>
            </Group>
        </Modal>
        
        <Modal
            opened={!!openDisponibilities}
            onClose={() => setOpenDisponibilities(null)}
            title={"Availabilities"}
            size="lg"
        >
            {(openDisponibilities || []).map((item, i) => <Paper mb="md" shadow="xs" p="md">
                <Button color="red" variant="subtle" onClick={() => setOpenDisponibilities(d => d.filter((item2, j) => i !== j))}>Remove</Button>
                <Group justify='center' mb="lg">
                    <InputField
                        name="start"
                        title={str("START")}
                        value={item.start}
                        fieldType={InputFieldTypes.TIME}
                        onChange={({start}) => setOpenDisponibilities(d => d.map((item, j) => i === j ? {...item, start} : item))}
                    />
                    <InputField
                        name="finish"
                        title={str("FINISH")}
                        value={item.finish}
                        fieldType={InputFieldTypes.TIME}
                        onChange={({finish}) => setOpenDisponibilities(d => d.map((item, j) => i === j ? {...item, finish} : item))}
                    />
                </Group>
                <Group justify='center'>
                    {Array(7).fill({}).map((wd, wdi) => 
                        <Button
                            onClick={() => {
                                setOpenDisponibilities(d => d.map((d, j) => i === j ? {
                                    ...d,
                                    weekdays: (d.weekdays || []).includes(wdi)
                                        ? (item.weekdays || []).filter(it => it !== wdi)
                                        : [...(item.weekdays || []), wdi]
                                } : d))
                            }}
                            variant={(item.weekdays || []).includes(wdi) ? 'filled' : 'outline'}>
                            {str(`WEEKDAYS.${wdi}`)[0]}
                        </Button>
                    )}
                </Group>
            </Paper>)}

            <Group justify="flex-end" mt="xs">
                <Button size="xs" onClick={() => setOpenDisponibilities(d => [...d, { weekdays: [], start: "00:00", finish: "00:00" }])} variant="subtle" leftSection={<FaPlus />}>Add Availability</Button>
            </Group>

            <Group justify='flex-end' mt="lg">
                <Button loading={loadingSave} variant='outline' color="gray" onClick={() => setOpenDisponibilities(null)}>{str("CANCEL")}</Button>
                <Button loading={loadingSave} variant='filled' color="violet" onClick={handleSaveDisponibilities}>{str("SAVE")}</Button>
            </Group>
        </Modal>
        
        <Modal
            opened={!!agentSchedule}
            onClose={() => setAgentSchedule(null)}
            styles={{content: {minHeight: 600}}}
        >
            <InputField
                fieldType={InputFieldTypes.SELECT}
                name="phone"
                value={agentSchedule?.phone}
                style={{flex: 1}}
                title={str("PHONE")}
                onChange={({ phone }) => setAgentSchedule(sp => ({ ...sp, phone }))}
                options={phones
                    .filter(p => (
                        ["in-use", "success"].includes(p?.data?.twilio?.status) ||
                        p?.data?.telnyx?.status
                    ))
                    .map((l) => ({ value: l._id, label: l.number }))}
            />
            <InputField
                name="datetime"
                title={str("DATETIME")}
                mt="md"
                value={agentSchedule?.datetime}
                onChange={({datetime}) => setAgentSchedule(u => ({...u, datetime}))}
                fieldType={InputFieldTypes.DATETIME}
            />
            <InputField
                name="contact"
                title={str("CONTACTS.TITLE")}
                mt="md"
                value={agentSchedule?.contact}
                onChange={({contact}) => {
                    setAgentSchedule(u => ({...u, contact }));
                }}
                fieldType={InputFieldTypes.SEARCH}
                searchType="contact"
            />
            <InputField
                name="agent"
                title={str("AGENTS.TITLE")}
                mt="md"
                value={agentSchedule?.agent}
                onChange={({agent}) => setAgentSchedule(u => ({...u, agent}))}
                fieldType={InputFieldTypes.SELECT}
                options={agents.map((agent) => ({ value: agent._id, label: `${agent.title}` }))}
            />

            <Group justify='flex-end' mt="lg">
                <Button loading={loadingSave} variant='outline' color="gray" onClick={() => setAgentSchedule(null)}>{str("CANCEL")}</Button>
                <Button loading={loadingSave} variant='filled' color="violet" onClick={handleSaveAgentSchedule}>{str("SAVE")}</Button>
            </Group>
        </Modal>

        <Modal
            opened={!!openIndisponibilities}
            onClose={() => setOpenIndisponibilities(null)}
            title={str("CALENDARS.UNAVAILABILITIES")}
            styles={{content: {minHeight: 600}}}
        >
            {(openIndisponibilities || []).map((item, i) => <>
                <Group justify='center' mb="lg" align='flex-end'>
                    <div style={{flex: 1}}>
                        <InputField
                            name="start"
                            title={str("START")}
                            value={item.start ? moment(item.start).toDate() : null}
                            fieldType={InputFieldTypes.DATETIME}
                            onChange={({start}) => setOpenIndisponibilities(d => d.map((item, j) => i === j ? {...item, start: start ? moment(start).format("YYYY-MM-DDTHH:mm:00") : null} : item))}
                        />
                    </div>
                    <div style={{flex: 1}}>
                        <InputField
                            name="finish"
                            title={str("FINISH")}
                            value={item.finish ? moment(item.finish).toDate() : null}
                            fieldType={InputFieldTypes.DATETIME}
                            onChange={({finish}) => setOpenIndisponibilities(d => d.map((item, j) => i === j ? {...item, finish: finish ? moment(finish).format("YYYY-MM-DDTHH:mm:59") : null} : item))}
                        />
                    </div>
                    <ActionIcon color="gray" size="xs" variant="subtle" onClick={() => setOpenIndisponibilities(d => d.filter((item2, j) => i !== j))}><FaTrash size={12} /></ActionIcon>
                </Group>
            </>)}

            <Group justify="flex-end" mt="xs">
                <Button size="xs" onClick={() => setOpenIndisponibilities(d => [...d, { weekdays: [], start: null, finish: null, }])} variant="subtle" leftSection={<FaPlus />}>Add New</Button>
            </Group>

            <Group justify='flex-end' mt="lg">
                <Button loading={loadingSave} variant='outline' color="gray" onClick={() => setOpenIndisponibilities(null)}>{str("CANCEL")}</Button>
                <Button loading={loadingSave} variant='filled' color="violet" onClick={handleSaveIndisponibilities}>{str("SAVE")}</Button>
            </Group>
        </Modal>
        
        <Modal
            title={str("CALENDARS.NEW")}
            opened={!!updating}
            onClose={() => { setUpdating(null) }} 
        >
            <InputField
                name="title"
                title={str("TITLE")}
                value={updating?.title}
                onChange={({title}) => setUpdating(u => ({...u, title}))}
            />
            <InputField
                name="timezone"
                title={str("TIMEZONE")}
                mt="md"
                value={updating?.timezone}
                onChange={({timezone}) => setUpdating(u => ({...u, timezone}))}
                fieldType={InputFieldTypes.SELECT}
                options={timezones.reduce((pv, tz) => {
                  return pv.concat(tz.utc.map(item => ({ value: item, label: `${item}` })))
                }, [])}
            />

            <Group justify='flex-end' mt="lg">
                <Button loading={loadingSave} variant='outline' color="gray" onClick={() => setUpdating(null)}>{str("CANCEL")}</Button>
                <Button loading={loadingSave} variant='filled' color="violet" onClick={handleSave}>{str("SAVE")}</Button>
            </Group>
        </Modal>
    </>
}
