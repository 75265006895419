import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Menu,
  Paper,
  Text,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import React from "react";
import { useLanguage } from "../../contexts/language.context";
import InputField, { InputFieldTypes } from "../input_field";
import { FaPlus, FaTrash } from "react-icons/fa";
import { timezones } from "../../utility/util";

export default function AddActions({ onSelect, actionOptions = [], whatsapps = [], calendars = [] }: any) {
  const { str } = useLanguage();
  const theme = useMantineTheme();

  return <Box p="md">
    <Text fw="bold" size="lg" c="gray.0">{str("AGENTS.ADD_ACTION_TITLE")}</Text>
    <Text fw="lighter" c="gray.0" mb="md" size="sm">{str("AGENTS.ADD_ACTION_SUBTITLE")}</Text>
    <Grid>
      {
        actionOptions.map((ao) => (
          <Grid.Col span={{ base: 12, md: 4 }}>
            <UnstyledButton
              style={{ width: '100%' }}
              onClick={() => {
                onSelect && onSelect({
                  type: ao.type,
                  description: ao.description,
                  timezone: ao.type === "book_appointment" ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined,
                })
              }}
            >
              <Paper p="md" style={{ backgroundColor: theme.colors.dark[8] }}>
                <Text fw="bold" c="gray.0" mb="xs">{ao.title}</Text>
                <Text c="gray.2" size="sm">{ao.subtitle}</Text>
              </Paper>
            </UnstyledButton>
          </Grid.Col>
        ))
      }
    </Grid>
  </Box>
}