import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Divider,
  Group,
  Menu,
  Modal,
  Paper,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import Helmet from 'react-helmet';
import { useClipboard, useMediaQuery } from "@mantine/hooks";
import { notifications, showNotification } from "@mantine/notifications";
import axios from 'axios';
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaAppStore, FaChevronDown, FaCompressArrowsAlt, FaExpand, FaFacebook, FaGooglePlay, FaLinkedin, FaPlus, FaReddit, FaSearch, FaSignOutAlt, FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SearchContact from "../components/search_contact";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import { useLanguage } from "../contexts/language.context";
// import JobPage from "../pages/job";
import NewJobPage from "../pages/new_job";
import { RESEND_VALIDATION } from "../services/auth";
import { GET_ALL_INVITES, SEND_INVITE } from "../services/companies";
import { GET_ALL_PROFILES } from "../services/profiles";
import { GET_ALL_NOTIFICATIONS, READ_NOTIFICATION, UPDATE_USER } from "../services/users";
import routes from "../utility/routes";
import { useQuery } from "../utility/util";

export type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const theme = useMantineTheme();

  const {
    user: currentUser,
    hasPermission,
    logout,
    planData,
    role,
    roles,
    userData,
    onChangeRole,
    loadUserData,
    user, 
  } = useAuth();
  const { socket, loadAgents, agents, startPlan, configs, mobileApp } = useApp();
  const { confirmDialog, newOrder, setNewOrder } = useApp();
  const { lang, setLang, str, availableLanguages } = useLanguage();
  const isMobile = useMediaQuery('(max-width: 991px)');

  const clipboard = useClipboard();

  const lsOpened = localStorage.getItem("@awaz/opened-sidebar");

  const navigate = useNavigate();
  const query = useQuery();
  const signed = query.get("signed") ? query.get("signed") === "true" : false;

  const location = useLocation();
  const { orderId : paramsOrderId } = useParams();
  const queryOrderId = query.get("order");
  const orderId = queryOrderId ?? paramsOrderId;

  const jobRef = useRef<any>();

  const [opened, setOpened] = useState(false);
  const [searchContact, setSearchContact] = useState(false);
  const [openedSidebar, setOpenedSidebar] = useState<boolean>(isMobile ? true : [null, undefined].includes(lsOpened) ? lsOpened === "true" : true);
  const [activeMenu, setActiveMenu] = useState(null);
  const [openTeam, setOpenTeam] = useState(null);
  const [inviteMails, setInviteMails] = useState({email: "", profile: null});
  const [profiles, setProfiles] = React.useState<any[]>([]);
  const [invites, setInvites] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [openWorkspaces, setOpenWorkspaces] = React.useState<boolean>(false);
  const [notificationsList, setNotificationsList] = React.useState<any[]>([]);
  const [openNotificationsMenu, setOpenNotificationsMenu] = React.useState<boolean>(false);

  const handleCloseJob = () => {
    if(paramsOrderId) navigate("/orders");
    if(queryOrderId){
        query.delete("order");
        navigate(`${location.pathname}${query.toString() ? `?${query.toString()}` : ''}`);
    }
  }
  
  const loadProfiles = () => {
    GET_ALL_PROFILES()
    .then(res => {
      setProfiles(res);
      setInviteMails(im => ({...im, profile: res.find(r => r.title === "Project Manager")?._id}))
    })
    .catch(err => {})
  }
  
  const translateStrings = async (lang) => {
    const defaultStrings = require("../strings/en-US.json");
    let count = 0;
    let items = [];
    let activeItem = {}; 
    for(let key in defaultStrings){
      let length = 1;
      if(typeof defaultStrings[key] === "object"){ for(let k2 in defaultStrings[key]) length += 1; }
      count += length;
      activeItem[key] = defaultStrings[key];
      if(count >= 20){
        items.push(activeItem);
        activeItem = {};
        count = 0;
      };
    }
    items.push(activeItem);
    const responses = await Promise.all(items.map(async item => {
      const { data } = await axios.post("https://api.openai.com/v1/chat/completions", {
        "model": "gpt-4",
        "messages": [
          {
            "role": "system",
            "content": `You will receive a JSON, your objective is translate it to '${lang}' keeping the keys. Reply only the translated json in the message. This texts were created to a UI platform, so you can improve not just translating but undestanding the context based on the key and the value`
          },
          {
            "role": "user",
            "content": JSON.stringify(item)
          },
        ]
      }, { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } })
      return JSON.parse(data.choices[0].message?.content);
    }))
    let res = responses.reduce((pv, it) => ({...pv, ...it}), {})
    console.log(lang, res)
    clipboard.copy(JSON.stringify(res));
  }
  
  const loadNotifications = () => {
    GET_ALL_NOTIFICATIONS()
    .then((res) => {
      setNotificationsList(res);
    })
    .catch(err => {})
  }
  
  const readNotification = (id) => {
    READ_NOTIFICATION(id)
    .then(() => {
      setNotificationsList(nl => nl.map(n => n._id === id ? { ...n, read: moment().format() } : n));
    })
    .catch(err => {
      // showNotification({message: err.message})
    })
  }
  
  const loadInvites = () => {
    GET_ALL_INVITES()
    .then(res => {
      setInvites(res.filter(i => i?.profile?.title !== "Client" && i.status === 0));
    })
    .catch(err => {})
  }
  
  const handleInvite = () => {
    // const canInvite = (planData?.seats || []).length > agents.length + invites.length;
    // if(!canInvite){
    //   notifications.show({ message: "To invite someone you need to upgrade your plan seats quantity." , color: 'yellow' })
    //   navigate("/settings#users")
    // }else{
      let previous = inviteMails;
      setInviteMails(im => ({...im, email: "" }))
      SEND_INVITE({ email: inviteMails.email, profile: inviteMails.profile })
      .then(res => {
        notifications.show({ message: str(`INVITE_SENT`), color: "green" })
        loadInvites();
      })
      .catch(err => {
        setInviteMails(previous);
        notifications.show({ message: err.message, color: "red" })
      })
    // }
  }

  const resendValidationMail = () => {
    RESEND_VALIDATION()
    .then(() => {
      notifications.show({message: "Verification e-mail sent", color: "green"})
    })
    .catch((err) => {
      notifications.show({message: err.message, color: "red"})
    })
  }

  const handleUpdateUserProfile = (userId, profileId) => {
    let profile = profiles.find(p => p._id === profileId);
    let previous = users;
    UPDATE_USER(userId, { profile: profile._id })
    .then(() => {
      loadAgents()
    })
    .catch(err => {
      notifications.show({ message: err.message, color: 'red' })
    })
  }
  
  const navbarContent: {
    title: string;
    key: string;
    icon: React.ReactNode;
    url: string;
  }[] = routes
    .filter(
      (route) =>
        route.menu &&
        hasPermission(route) &&
        (!route.condition || route.condition({ user: userData?.user, role }))
    )
    .map(({ title, key, icon, url }) => ({ title, key, icon, url }));

  useEffect(() => {
    localStorage.setItem("@awaz/opened-sidebar", openedSidebar.toString())
  }, [openedSidebar]);
  
  useEffect(() => {
    // if(signed){ startPlan(); }
    if(userData && role && userData?.plan?.trial && role?.profile?.title !== "Client"){ startPlan(); }
  }, [userData, role]);
  
  useEffect(() => {
    if(userData && !userData.active){
      showNotification({ message: "Your plan is inactive. Check the billing section" });
      navigate("/settings#billing");
    }
  }, [userData, window.location.pathname]);
  
  useEffect(() => {
    if(isMobile) setOpenedSidebar(true)
  }, [isMobile]);

  useEffect(() => {
    if (socket) {
      const func = (notification) => {
        notifications.show({ message: notification.body, title: notification.title, color: "yellow" });
        setNotificationsList(n => [notification, ...n])
      };
      socket.on(`user-notifications-${currentUser?._id}`, func);
      return () => {
        socket.removeAllListeners(`user-notifications-${currentUser?._id}`);
      };
    }
  }, [socket, currentUser]);
  
  useEffect(() => {
    loadProfiles()
    loadInvites()
    loadNotifications()
  }, [role]);

  // React.useEffect(() => {
  //   if(orderId){
  //     const handleClickOutside = (event) => {
  //       if (jobRef.current && !jobRef.current.contains(event.target)) {
  //         handleCloseJob();
  //       }
  //     }
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }
  // }, [jobRef, orderId]);

  React.useEffect(() => {
    let url = window.location.pathname;
    let findIndex = navbarContent.findIndex((r) => r.url === url);

    if (findIndex !== -1) {
      setActiveMenu(findIndex);
    }else{
      findIndex = navbarContent.findIndex((r) => r.url.split("/").some(s => s && url.split("/").includes(s)));
      if(findIndex !== -1) setActiveMenu(findIndex);
    }
  }, [window.location.pathname]);

  return  <AppShell
    header={{ height: 80 }}
    navbar={{
      width: openedSidebar ? 240 : 60, breakpoint: 'md', collapsed: { mobile: !opened } }}
    styles={{
      main: {
        // marginRight: -15,
        // marginTop: -17,
        // marginLeft: -15,
        backgroundColor: theme.colors.dark[8],
      },
      root: {padding: 0},
      header: {
        left: isMobile ? 0 : openedSidebar ? 240 : 60,
        // left: 0,
      },
      navbar: {
        top: 0,
        height: '100vh',
        zIndex: 2,
        padding: 0,
        backgroundColor: theme.colors.dark[9],
        borderRightColor: theme.colors.dark[8],
        paddingTop: isMobile ? 70 : 0,
      }
    }}
    padding="md"
  >
    <AppShell.Header>
      <div style={{
        // backgroundImage: "linear-gradient(270deg, #1E2028 0%, #1E2028 100%)",
        backgroundColor: theme.colors.dark[9],
        zIndex: 2,
        height: 80,
        padding: isMobile ? '0 8px' : '0 20px',
        WebkitBoxShadow: `2px 0px 5px 0px ${theme.colors.dark[9]}`,
        MozBoxShadow: `2px 0px 5px 0px ${theme.colors.dark[9]}`,
        boxShadow: `2px 0px 5px 0px ${theme.colors.dark[9]}`,
        borderBottom: `1px solid ${theme.colors.dark[9]}`,
      }}>
        <div
          style={{ display: "flex", alignItems: "center", height: "100%", textAlign: 'center' }}
        >
          {isMobile && <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color="gray.0"
            mr="xl"
          />}

          <div style={{flex: 1}}></div>
          
          {/* {!isMobile && <Group style={{flex: 1}} justify="flex-start">
              <img
                onClick={() => { navigate("/") }}
                src={openedSidebar ? configs.image ?? "/icon.png" : configs.icon ?? "/icon.png"}
                alt="Awaz"
                style={{ height: 40, objectFit: 'contain', borderRadius: 10, marginRight: 0, cursor: 'pointer' }}
              />
              {navbarContent.map((item, i) => (
                // <Tooltip key={`${i}`} position="bottom" label={item.title}>
                  <Button
                    variant={i === activeMenu ? "filled" : "light"}
                    color="violet"
                    size="sm"
                    onClick={() => {
                      setOpened(false);
                      navigate(item.url);
                      setActiveMenu(i)
                    }}
                  >
                    {str(`NAVBAR.${item.key}`)}
                  </Button>
                // </Tooltip>
              ))}
            </Group>
          } */}

          <Group style={{gap: isMobile ? 4 : 20}}>
            {userData?.plan?.trial && role?.profile?.title !== "Client" &&
              <Button
                onClick={() => startPlan()}
                variant="filled"
                style={{borderColor: 'black', background: 'gray.0'}}
                c="black"
                size={isMobile ? "xs" : "sm"}
              >{str("UPGRADE")}</Button>}
            {(planData?.active || role?.profile?.title === "Client") &&
              <Button
                onClick={() => setNewOrder({})}
                leftSection={<FaPlus />}
                variant="filled"
                style={{borderColor: 'black', background: 'gray.0'}}
                c="black"
                size={isMobile ? "xs" : "sm"}
              >{str("CREATE_NEW_ORDER")}</Button>}
            {/* <a href="#" data-productlift-sidebar="02df45a5-7738-4bce-9dc6-29f03939171a">Open Widget</a> */}
            <ActionIcon
              onClick={() => setSearchContact(true)}
              size={36} variant="transparent" color="gray.0">
              <FaSearch />
            </ActionIcon>

            {/* <Menu
              position="bottom-end"
              opened={openNotificationsMenu}
              onClose={() => setOpenNotificationsMenu(false)}
              onOpen={() => setOpenNotificationsMenu(true)}
            >
              <Menu.Target>
                <ActionIcon size={36} variant="transparent" color="gray.0">
                  {notificationsList.some(n => !n.read) &&
                    <Badge style={{position: 'absolute', top: -10, right: -10}} size="sm" color="yellow" variant="filled">{notificationsList.filter(n => !n.read).length}</Badge>}
                  <FaBell />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown
                style={{
                  width: 250,
                }}
                m="md"
              >
                <Text p="sm" ta="center">{str("NOTIFICATIONS")}</Text>
                <Menu.Divider />
                <Box style={{maxHeight: 300, overflowY: 'auto'}}>
                  {notificationsList.length === 0 && <Text size="xs" ta="center" p="md">{str("NO_NOTIFICATIONS")}</Text>}
                  {notificationsList.map(not => <>
                    <UnstyledButton p="xs" style={{width: '100%'}} key={not._id} onClick={() => {
                        setOpenNotificationsMenu(false);
                        readNotification(not._id);
                        if(not.job) navigate(`/orders/${not.job._id}`);
                        else if(not.support) navigate(`/support/${not.support._id}`);
                      }}>
                      <Group>
                        <Text style={{flex: 1}} fw={not.read ? "normal" : "bold"} size="sm">{not.title}</Text>
                        {!not.read && <Indicator color="yellow" position="middle-end"></Indicator>}
                      </Group>
                      <Text fw={not.read ? "normal" : "bold"} size="xs">{not.body}</Text>
                      <Text size="xs" ta="right">{getExtenseDatetime(not.createdAt)}</Text>
                    </UnstyledButton>
                    <Divider mt="xs" mb="xs" />
                  </>)}
                </Box>
              </Menu.Dropdown>
            </Menu> */}

            {/* <Menu>
              <Menu.Target>
                <ActionIcon size={36} variant="filled" color="red">
                  <img
                    src={availableLanguages.find(al => al.key === lang)?.icon ?? "/countries/us.png"}
                    style={{width: 20, height: 20, objectFit: 'contain'}} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {
                  availableLanguages.filter(al => al.key !== lang).map(al => (
                    <Menu.Item
                      onClick={() => translateStrings(al.key)}
                      leftSection={al.icon && <img src={al.icon} style={{width: 20, height: 20, objectFit: 'contain'}} />}
                    >
                      {al.title}
                    </Menu.Item>
                  ))
                }
              </Menu.Dropdown>
            </Menu> */}
            
            <Menu>
              <Menu.Target>
                <ActionIcon size={36} variant="transparent" color="gray.0">
                  <img
                    src={availableLanguages.find(al => al.key === lang)?.icon ?? "/countries/us.png"}
                    style={{width: 20, height: 20, objectFit: 'contain'}} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {
                  availableLanguages.filter(al => al.key !== lang).map(al => (
                    <Menu.Item
                      onClick={() => setLang(al.key)}
                      leftSection={al.icon && <img src={al.icon} style={{width: 20, height: 20, objectFit: 'contain'}} />}
                    >
                      {al.title}
                    </Menu.Item>
                  ))
                }
              </Menu.Dropdown>
            </Menu>
            

            <UnstyledButton
              size={36}
              onClick={() => navigate("/settings#profile")}
            >
              <Avatar src={userData?.user?.image} />
            </UnstyledButton>
          </Group>
          </div>
        </div>
    </AppShell.Header>

    <AppShell.Navbar>
      <AppShell.Section grow style={{ overflowY: "auto" }}>
        <div style={{textAlign: 'center', padding: '40px 0 20px 0'}}><img
          src={openedSidebar ? configs.image ?? "/logo-white.png" : configs.icon ?? "/icon.png"}
          alt="Awaz"
          style={{ maxHeight: isMobile ? 60 : 80, maxWidth: isMobile ? '40%' : '60%', objectFit: 'contain' }}
          /></div>
        <div style={{position: 'relative'}}>
          {openedSidebar && <UnstyledButton
            onClick={() => setOpenWorkspaces(true)} style={{width: '100%', padding: 20}}>
            {/* <div style={{display: 'flex', background: 'gray.0', flexDirection: 'row', alignItems: 'center', border: '1px solid black', borderRadius: 8}}>
              <Avatar size={38} src={userData?.user?.image} color="black" style={{borderRadius: '8px 0 0 8px'}} />
              <FaChevronDown style={{margin: '0 2px'}} size={12} />
            </div> */}
            <Paper p="xs" pl="md" pr="md">
              <Group>
                <Text style={{flex: 1}} size="sm" c="gray.0" fw="bold">{role?.company?.name}</Text>
                <FaChevronDown />
              </Group>
            </Paper>
          </UnstyledButton>}

          {/* <div style={{
            background: 'gray.0',
            height: openedSidebar ? 65 : 50,
            position: 'absolute',
            top: navbarContent.findIndex((n,i) => i === activeMenu) > 0 ? navbarContent.findIndex((n,i) => i === activeMenu) * 65 : 0,
            left: 0,
            right: 0,
            zIndex: -1,
            transition: `0.3s all ease`,
            borderBottom: '4px solid gray.9',
            borderRight: '1px solid gray.9',
            borderLeft: '1px solid gray.9',
            borderTop: '1px solid gray.9',
          }}></div> */}
          {navbarContent.map((item, i) => (
            // <Tooltip key={`${i}`} position="bottom" label={item.title}>
              <UnstyledButton
                style={{
                  width: '100%',
                  padding: !openedSidebar ? 0 : '8px 20px',
                }}
                onClick={() => {
                  setOpened(false);
                  navigate(item.url);
                  setActiveMenu(i)
                }}
              >
                <Group
                  style={{
                    borderRadius: 6,
                    padding: 10,
                    ...((i === activeMenu && openedSidebar) ? {
                      backgroundColor: theme.colors.dark[3],
                    } : {})
                  }}
                >
                  {<ActionIcon
                      variant="transparent"
                      color="gray.0"
                      key={item.key}
                    >{item.icon}</ActionIcon>}
                  {openedSidebar && <Text
                    size="sm"
                    c="gray.0"
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {str(`NAVBAR.${item.key}`)}
                  </Text>}
                </Group>
              </UnstyledButton>
            // </Tooltip>
          ))}
        </div>
      </AppShell.Section>
      {openedSidebar && userData?.company?.defaultDomain && role?.profile?.title !== "Client" && <AppShell.Section mb="xs">
        <Group align="center" justify="center" mt="xs">
          <Tooltip label="Share on Facebook"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaFacebook /></ActionIcon></Tooltip>
          <Tooltip label="Share on Whatsapp"><ActionIcon variant="light" onClick={() => {
            window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(userData?.company?.name)}%20-%20${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaWhatsapp /></ActionIcon></Tooltip>
          <Tooltip label="Share on Reddit"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.reddit.com/submit?url=${encodeURIComponent(userData?.company?.defaultDomain)}&title=${encodeURIComponent(userData?.company?.name)}`, '_blank');
          }}><FaReddit /></ActionIcon></Tooltip>
          <Tooltip label="Share on X (Twitter)"><ActionIcon variant="light" onClick={() => {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(userData?.company?.defaultDomain)}&text=${encodeURIComponent(userData?.company?.name)}`, '_blank');
          }}><FontAwesomeIcon icon={["fab", "x-twitter"]} /></ActionIcon></Tooltip>
          <Tooltip label="Share on Linkedin"><ActionIcon variant="light" onClick={() => {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(userData?.company?.defaultDomain)}`, '_blank');
          }}><FaLinkedin /></ActionIcon></Tooltip>
        </Group>
      </AppShell.Section>}
      {mobileApp?.developer?.deployed && role?.profile?.title === "Client" && <Paper style={{borderColor: 'gray.0'}} p="xl">
          <Text size="xs" mb="md" ta="center">Download The Mobile App</Text>
          <Group>
              {mobileApp?.developer?.play_store_url && <Box style={{flex: 1}}>
                  <Paper onClick={() => window.open(mobileApp?.developer?.play_store_url, "_blank")} p="xs" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: 'gray.0'}}>
                      <FaGooglePlay size={18} color="gray.9" />
                  </Paper>
              </Box>}
              {mobileApp?.developer?.apple_url && <Box style={{flex: 1}}>
                  <Paper onClick={() => window.open(mobileApp?.developer?.apple_url, "_blank")} p="xs" style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', borderRadius: 10, flexDirection: 'row', alignItems: 'center', background: 'gray.0'}}>
                      <FaAppStore size={18} color="gray.9" />
                  </Paper>
              </Box>}
          </Group>
      </Paper>}
      {!isMobile && <AppShell.Section>
        <Tooltip
          position="right"
          label={openedSidebar ? str("COLLAPSE") : str("EXPAND")}
        >
          <Box>
            {openedSidebar
            ? <Button
              onClick={() => setOpenedSidebar(s => !s)}
              fullWidth
              variant="transparent"
              color="gray.0"
              size="xs"
              leftSection={<FaCompressArrowsAlt />}
            >{str("COLLAPSE")}</Button>
            : <ActionIcon
              color="gray.0"
              ml="xs" mb="xs" variant="transparent" onClick={() => setOpenedSidebar(s => !s)} size="lg"><FaExpand /></ActionIcon>}
            <Text></Text>
          </Box>
        </Tooltip>
      </AppShell.Section>}
    </AppShell.Navbar>

    <AppShell.Main>
      <div style={{position: 'relative'}}>
        <div id="principal-content" style={{padding: '0 0 200px 0'}}>
          <div style={{padding: isMobile ? 0 : 30}}>
            {children}
          </div>
        </div>

        {user && !userData?.user?.verified && <UnstyledButton style={{width: '100%'}} onClick={resendValidationMail}>
          <Box p="xl" style={{position: 'fixed', borderTop: '1px solid #8a8a8a', zIndex: 10, left: 0, bottom: 0, right: 0, textAlign: 'center', background: '#12141d'}}>
            <Text c="gray.0">{str("MAIL_VERIFICATION_TITLE")}</Text>
            <Text c="gray.0" fw="bold">{str("MAIL_VERIFICATION_SUBTITLE")}</Text>
          </Box>
        </UnstyledButton>}
      </div>

      {/* <Helmet>
        <script id="chat-init" src="https://cloud.board.support/account/js/init.js?id=588018397"></script>
      </Helmet> */}

      <Modal
        opened={openWorkspaces}
        onClose={() => setOpenWorkspaces(false)}
      >
        <Text size="md" c="gray.0">Workspaces</Text>
        <Divider mt="md" mb="md" />
        {roles.filter(r => r.company._id !== role?.company?._id).length === 0 && <Text mt="sm" size="md" c="gray">No other workspace</Text>}
        {
          roles.filter(r => r.company._id !== role?.company?._id).map(role => <>
            <Button
              fullWidth
              variant="transparent"
              c="gray.0"
              size="md"
              onClick={() => {
                onChangeRole(role._id);
                // navigate("/");
              }}
            >{role?.company?.name}</Button>
            <Divider mt="md" mb="md" />
          </>)
        }
        <Box mt="md" mb="md">
          {/* {role && roles.some(r => r.company._id === role.company._id && r.profile.title !== "Client") &&
          <Switch mb="md" label="View as Client" checked={role?.profile?.title === "Client"} onChange={() => {
            onChangeRole(role._id, role?.profile?.title !== "Client")
          }} />} */}
          {/* <RolesSelect /> */}
          {/* <Text mt="xs" align="left" size="md" c="violet" fw="bold">Language</Text> */}
        </Box>
        <Button
          onClick={() => {
            confirmDialog(
              { text: str("CONFIRMATION.LOGOUT") },
              ({ confirmed }) => {
                if (confirmed) logout();
              }
            );
          }}
          leftSection={<FaSignOutAlt />}
        >{str("LOGOUT")}</Button>
      </Modal>

      <Modal
        opened={!!newOrder}
        onClose={() => setNewOrder(null)}
        size="calc(80vw)"
        withCloseButton={false}
      >
        <NewJobPage onClose={() => setNewOrder(null)} />
      </Modal>

      <SearchContact
        open={searchContact}
        onClose={() => { setSearchContact(false) }}
      />

    </AppShell.Main>
  </AppShell>
};

export default Layout;
