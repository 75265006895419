import { ActionIcon, Avatar, Button, Group, Title } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { FaCopy } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa6";

export default function Zapier(){
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const clipboard = useClipboard();

    // const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("api_keys")
        .then((res) => {
            setData(res)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        
        SET_COMPANY_INTEGRATION("api_keys", {...data}, [{key: "key"}])
        .then((res) => {
            setLoadingSave(false);
            loadData()
            notifications.show({message: "Re-generated API KEY", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <FaNetworkWired size={40} />
            <Title c="gray.0" order={5}>API Key</Title>
        </Group>
        <Group align="flex-end" mt="md">
            <InputField
                name="key"
                style={{flex: 1}}
                value={data?.key}
                fieldType={InputFieldTypes.PASSWORD}
                readOnly
                title="API KEY"
            />
            <ActionIcon size="xl" onClick={() => {
                clipboard.copy(data?.key);
                notifications.show({ message: "Copied to clipboard", color: "yellow" })
            }} color="gray"><FaCopy /></ActionIcon>
        </Group>

        <Group justify="flex-end" mt="md">
            <Button size="xs" onClick={handleSave} loading={loadingSave}>Re-Generate</Button>
        </Group>
    </>
}
