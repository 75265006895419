import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Modal,
  Pagination,
  Paper,
  Tabs,
  Text,
  Tooltip,
  UnstyledButton
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import {
  FaFileExport,
  FaFileImport,
  FaPencilAlt,
  FaTrash,
  FaUpload
} from "react-icons/fa";
import { Buffer } from 'buffer';
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleHeader from "../components/simple_header";
import { useApp } from "../contexts/app.context";
import { useAuth } from "../contexts/auth.context";
import {
  DELETE_CONTACT_FROM_LIST,
  DELETE_CONTACT_LIST,
  GET_CONTACT_LIST,
  GET_CONTACT_LIST_CONTACTS,
  SAVE_CONTACT_LIST
} from "../services/contacts";
import Table from "../components/table";
import { parseKeyword } from "../utility/util";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import NewContact from "../components/new_contact";
import { GET_ALL_TAGS } from "../services/tags";
import InputField, { InputFieldTypes } from "../components/input_field";
import { useLanguage } from "../contexts/language.context";
import { useMediaQuery } from "@mantine/hooks";

export default function Home() {
  const { id: listId } = useParams();
  const { user: currentUser, userData } = useAuth();
  const { role } = useAuth();

  const navigate = useNavigate();

  const { confirmDialog } = useApp();
  const { str } = useLanguage();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const utf8 = require("utf8");

  const [contacts, setContacts] = useState<any[]>([]);
  const [data, setData] = useState<any>(null);
  const [filters, setFilters] = useState<any>({
    search: "",
    tags: [],
  });
  const [selectedTab, setSelectedTab] = useState<string>("all");
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [validFile, setValidFile] = useState<boolean>(false);
  const [shownContacts, setShownContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [quantity, setQuantity] = useState(20);
  const [uploadingFile, setUploadingFile] = useState<any>(null);
  const [newContact, setNewContact] = useState<any>(null);
  const [tags, setTags] = useState<any[]>([]);
  const [tagsCategories, setTagsCategories] = useState<any[]>([]);

  const loadTags = () => {
    GET_ALL_TAGS()
      .then(({ tags = [], categories = [] }) => {
        setTags(tags);
        setTagsCategories(categories);
      })
      .catch((err) => { });
  };

  const loadData = (setLoading = true) => {
    if (listId && listId !== "unsigned") {
      setLoadingData(setLoading);
      GET_CONTACT_LIST(listId)
        .then((dt) => {
          setLoadingData(false);
          setData(dt);
        })
        .catch((err) => {
          setLoadingData(false);
          notifications.show({ title: "Ops", message: err.message, color: "red", });
        });
    }
  };

  const loadContacts = () => {
    if (listId) {
      setLoadingContacts(true)
      GET_CONTACT_LIST_CONTACTS(listId)
        .then((items) => {
          setLoadingContacts(false)
          setContacts(items.sort((a, b) => a.name > b.name ? 1 : -1));
        })
        .catch((err) => {
          setLoadingContacts(false)
        });
    }
  };

  useEffect(() => {
    const keys: string[] = parseKeyword(filters.search)?.split(" ");
    setShownContacts(contacts.filter(c => (
      [
        ...(c.name ? c.name.split(" ") : []),
        ...(c.identifiers || []).map(item => item.value || " ")
      ].some(k => (
        keys.every(k2 => parseKeyword(k).includes(k2)))
      ) && (
        (filters.tags ?? []).every(t => c.tags.map(ct => ct._id).includes(t))
      ) &&
      (
        selectedTab === "all" ||
        (selectedTab === "unsubscribe" && !!c.unsubscribe) ||
        (selectedTab === "contacted" && !!c.contacted) ||
        (selectedTab === "not_contacted" && !c.contacted)
      )
    )))
  }, [contacts, filters, selectedTab])

  // const deleteContact = (id) => {
  //   setLoadingDeleteContacts((d) => ({ ...d, [id]: true }));

  //   DELETE_CONTACT(id)
  //     .then(() => {
  //       setLoadingDeleteContacts((d) => ({ ...d, [id]: false }));
  //       navigate("/contatos");
  //       showNotification({
  //         title: "Sucesso",
  //         message: "Contato deletado com sucesso",
  //         color: "green",
  //       });
  //     })
  //     .catch((err) => {
  //       setLoadingDeleteContacts((d) => ({ ...d, [id]: false }));
  //       alert(err.message);
  //     });
  // };

  // const handleSaveContact = () => {
  //   if (listId) {
  //     setLoadingSave(true);
  //     const { props } = variables;
  //     const params = {
  //       extras,
  //       lists: selectedLists,
  //       identifiers,
  //       name: contactName,
  //     };

  //     UPDATE_CONTACT(params, listId)
  //       .then((dt) => {
  //         setLoadingSave(false);
  //         showNotification({
  //           title: "Sucesso",
  //           message: "Contato atualizado com sucesso",
  //           color: "green",
  //         });
  //       })
  //       .catch((err) => {
  //         setLoadingSave(false);
  //         showNotification({
  //           title: "Ops",
  //           message: err.message,
  //           color: "red",
  //         });
  //       });
  //   }
  // };

  const deleteContactList = () => {
    DELETE_CONTACT_LIST(listId)
      .then(() => {
        navigate("/lists");
      })
      .catch(err => {
        notifications.show({ message: err.message, color: "red" });
      })
  }

  const removeContact = (contactId) => {
    DELETE_CONTACT_FROM_LIST(contactId, listId)
      .then(() => {
        setContacts(c => c.filter(item => item._id !== contactId));
      })
      .catch(err => {
        notifications.show({ message: err.message, color: "red" });
      })
  }

  const importCsv = () => {
    if (uploadingFile.file) {
      setLoadingImport(true);
      let params = new FormData();
      params.append("_id", listId);
      params.append("file", uploadingFile.file);
      SAVE_CONTACT_LIST(params)
        .then(() => {
          setLoadingImport(false);
          loadContacts();
          setUploadingFile(null);
        })
        .catch(err => {
          setLoadingImport(false);
          notifications.show({ message: err.message, color: "red" });
        })
    }
  }
  const handleExportCsv = () => {
    const items = [
      ["Name", "Phone", "Email", ...userData?.company?.fields.map(f => f.title)]
    ]

    for (let c of shownContacts) {
      items.push([
        c.name, c.phone, c.email, ...userData?.company?.fields.map(f => c.extras[f.title] ?? ""),
      ])
    }

    const csvData = items.map(it => it.map(k => `"${k ?? ""}"`).join(";")).join("\n")
    // setLoadingExport(true)
    // EXPORT_CALLS({ ...filters, page })
    // .then(res => {
    // setLoadingExport(false)
    // for(let file of res.files ?? []){
    // if(file.url) window.open(file.url, "_blank");
    // if(file.base64){
    const byteCharacters = Buffer.from(csvData, "utf-8");
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters[i];
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: "application/csv" });

    const url = URL.createObjectURL(blob);;
    const a = document.createElement('a');
    a.href = url;
    a.download = `contacts.csv`;
    a.click();
    URL.revokeObjectURL(url);
    // }
    // }
    // })
    // .catch(err => {
    // setLoadingExport(false)
    // notifications.show({ message: err.message, color: "red" })
    // })
  }

  const handleDropFile = (files) => {
    console.log(files);
    const file = files[0];
    setUploadingFile(l => ({ ...l, file }));
    if (file) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        try {
          const text = (e.target.result) as string;
          const lines = text.split("\n");
          const columns = lines[0].split(",").map(k => k.toLocaleLowerCase().replace("\r", ""));
          console.log(columns);
          setValidFile(columns.includes("phone") && columns.includes("name"))
        } catch (err) {
          setValidFile(false)
          notifications.show({ message: err.message, color: 'red' });
        }
      };
      reader.readAsText(file)
    } else {
      setValidFile(false)
    }
  }

  useEffect(() => {
    loadData();
    loadContacts();
    loadTags();
  }, [listId]);

  const transformIdentifierData = (identifiers: any) => {
    let newIdentifiers = Object.assign([], identifiers);
    if (identifiers.length === 2 && identifiers[0].key === "phone") {
      return newIdentifiers;
    } else {
      if (newIdentifiers.length === 0) {
        newIdentifiers.push({ key: "phone", value: "" });
        newIdentifiers.push({ key: "email", value: "" });
      } else if (newIdentifiers[0].key === "email") {
        newIdentifiers.unshift({ key: "phone", value: "" });
      } else if (newIdentifiers[0].key === "phone") {
        newIdentifiers.push({ key: "email", value: "" });
      }
    }
    return newIdentifiers;
  };

  return <Box p="lg">
    <Paper mb="md" p="md">
      <Group align="flex-end" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <Menu>
          <Menu.Target>
            <Button leftSection={<FaFileImport />}>{str("CONTACTS.ADD_CONTACTS")}</Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => setNewContact({})}>{str("CONTACTS.ADD_CONTACT")}</Menu.Item>
            <Menu.Item onClick={() => setUploadingFile({})}>{str("IMPORT_CSV")}</Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Grid style={{ flex: 1 }} justify="flex-end">
          {[
            tags.some(t => !t.category) && { _id: undefined, title: str("OTHERS") },
            ...tagsCategories,
          ].filter(nn => nn).map(tc => {
            const selfTags = (tags ?? []).filter(t => t.category === tc._id)
            return <Grid.Col key={`${tc._id}`} span={{ base: 12, md: 4 }}>
              <InputField
                name="tags"
                value={filters?.tags.filter(t => selfTags.some(t2 => t2._id === t))}
                onChange={({ tags }) => {
                  setFilters(f => {
                    let tgs = [...tags, ...f.tags.filter(t => !selfTags.map(t2 => t2._id).includes(t))]
                    return { ...f, tags: tgs }
                  })
                }}
                fieldType={InputFieldTypes.SELECT}
                title={tc.title}
                multiple
                options={selfTags
                  .map(t => ({
                    value: t._id,
                    label: `${t.title} (${contacts.filter(c => (c.tags ?? []).map(t2 => t2._id).includes(t._id)).length})`
                  }))}
              />
            </Grid.Col>
          })}
        </Grid>
      </Group>
    </Paper>
    <SimpleHeader
      title={
        <Group gap={20}>
          <Text fw="bold" size="xl">{data?.title ?? str("CONTACTS.UNSIGNEDS")}</Text>
          <Divider size="sm" color="white" orientation="vertical" />
          <Link to="/lists"><Text c="white" size="sm">{str("CONTACTS.BACK_TO_LISTS")}</Text></Link>
        </Group>
      }
      right={
        <Group>
          {/* <Button
            onClick={()}
          >
            Custom Fields
          </Button> */}
          <ActionIcon
            onClick={() => {
              confirmDialog({ text: str("CONTACTS.LISTS_DELETE_CONFIRM") }, ({ confirmed }) => {
                if (confirmed) {
                  deleteContactList()
                }
              })
            }}
          >
            <FaTrash />
          </ActionIcon>
        </Group>
      }
    />
    <Group align="flex-end">
      <Tabs
        value={selectedTab ?? "all"}
        onChange={setSelectedTab}
        variant="outline"
        style={{ flex: 1 }}
        styles={{ tab: { borderWidth: 4 } }}
      >
        <Tabs.List>
          <Tabs.Tab miw={200} value="all">{str("ALL")} <Badge>{contacts.length}</Badge></Tabs.Tab>
          <Tabs.Tab miw={200} value="contacted">{str("CONTACTS.CONTACTED")} <Badge>{contacts.filter(c => !!c.contacted).length}</Badge></Tabs.Tab>
          <Tabs.Tab miw={200} value="not_contacted">{str("CONTACTS.NOT_CONTACTED")} <Badge>{contacts.filter(c => !c.contacted).length}</Badge></Tabs.Tab>
          <Tabs.Tab miw={200} value="unsubscribe">{str("CONTACTS.UNSUBSCRIBED")} <Badge>{contacts.filter(c => !!c.unsubscribe).length}</Badge></Tabs.Tab>
        </Tabs.List>
      </Tabs>
      <Tooltip label="Export data">
        <Button size="sm" variant="outline" onClick={() => {
          handleExportCsv()
        }} rightSection={<FaFileExport />}>{str("EXPORT")}</Button>
      </Tooltip>
    </Group>
    <Table
      sort={{ key: "name" }}
      columns={[
        { title: str("NAME"), key: 'name', style: { textAlign: 'left' } },
        { title: str("EMAIL"), key: 'email', style: { textAlign: 'left' } },
        { title: str("PHONE"), key: 'phone', style: { textAlign: 'left' } },
        {
          title: str("EDIT"), key: 'edit', itemStyle: { textAlign: 'center' }, render: (item) => (
            <ActionIcon
              color="gray"
              variant="outline"
              onClick={() => { navigate(`/contacts/${item._id}`) }}
            ><FaPencilAlt /></ActionIcon>
          )
        },
        listId !== "unsigned" && {
          title: str("DELETE"), key: 'delete', itemStyle: { textAlign: 'center' }, render: (item) => (
            <ActionIcon
              color="gray"
              variant="outline"
              onClick={() => {
                confirmDialog({ text: str("CONTACTS.REMOVE_CONTACT_FROM_LIST") }, ({ confirmed }) => {
                  if (confirmed) {
                    removeContact(item._id)
                  }
                })
              }}
            ><FaTrash /></ActionIcon>
          )
        },
      ].filter(nn => nn)}
      data={shownContacts.slice((page - 1) * quantity, (page) * quantity)}
    />

    <Group justify='flex-end'>
      <Pagination m="lg" total={(shownContacts.length / quantity) + 1} value={page} onChange={setPage} />
    </Group>

    <NewContact
      lists={(listId && listId !== "unsigned") ? [listId] : []}
      onSave={(c) => { loadContacts() }}
      open={Boolean(newContact)}
      data={newContact}
      onChange={(dt) => setNewContact(nt => ({ ...nt, ...dt }))}
      onClose={() => setNewContact(null)}
    />

    <Modal opened={!!uploadingFile} onClose={() => setUploadingFile(null)}>
      <Dropzone
        name="file"
        title='CSV File'
        accept={[MIME_TYPES.csv]}
        onDrop={handleDropFile}
      >
        <Group>
          <FaUpload size={35} />

          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '0 20px' }}>
              {
                !!uploadingFile?.file
                  ? <Text size="md" inline>
                    {str("UPLOAD_FILE_TITLE")}
                  </Text>
                  : <Text size="sm" c="dimmed" inline mt={7}>
                    {str("UPLOAD_FILE_SUBTITLE")}
                  </Text>
              }
            </div>
          </div>
        </Group>
      </Dropzone>
      <UnstyledButton
        style={{ width: '100%' }}
        onClick={() => {
          window.open("/list_template.csv", "_blank");
        }}
      >
        <Text td="underline" size="md" mt="md" c="gray">{str("CONTACTS.DOWNLOAD_CSV_TEMPLATE")}</Text>
        <div style={{
          marginTop: 20,
          height: 200,
          width: '100%',
          backgroundImage: 'url(/list_template.png)',
          backgroundRepeat: "no-repeat",
          backgroundSize: 'contain',
        }}></div>
      </UnstyledButton>
      {uploadingFile?.file && !validFile && <Text ta="right" mt="md" c="red" size="xs">{str("CONTACTS.IMPORT_CSV_ERROR")}</Text>}
      <Group mt="xl" justify='flex-end'>
        <Button loading={loadingImport} disabled={!validFile} onClick={importCsv}>{str("SAVE")}</Button>
      </Group>
    </Modal>

    <LoadingOverlay visible={loadingData || loadingContacts} />
  </Box>
}
