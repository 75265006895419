import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { Avatar, Button, Grid, Group, Loader, Paper, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { CONNECT_TWILIO, GET_ALL_PHONES, GET_ALL_TWILIOS, SAVE_PHONE } from "../../services/phones";

export default function Twilio() {
    const [items, setItems] = useState<any[]>([]);
    const [phones, setPhones] = useState<any[]>([]);
    const [data, setData] = useState<any>(null);
    const [connecting, setConnecting] = useState<any>({});
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);

    const theme = useMantineTheme();

    const handleChange = (dt: any) => setData((u: any) => ({ ...u, ...dt }))

    const loadData = () => {
        setLoadingItems(true)
        GET_ALL_TWILIOS()
            .then((res) => {
                setItems(res)
            })
            .catch(err => {
                notifications.show({ title: "Ops.", message: err.message, color: 'red' })
            })
            .finally(() => {
                setLoadingItems(false)
            })
    }

    const loadPhones = () => {
        GET_ALL_PHONES()
            .then((res) => {
                setPhones(res)
            })
            .catch(err => {
                notifications.show({ title: "Ops.", message: err.message, color: 'red' })
            })
    }

    const handleSave = () => {
        setLoadingSave(true)
        CONNECT_TWILIO(data)
            .then((res) => {
                loadData();
            })
            .catch(err => {
                notifications.show({ title: "Ops.", message: err.message, color: 'red' })
            })
            .finally(() => {
                setLoadingSave(false)
            })
    }

    React.useEffect(() => { loadData() }, [])
    React.useEffect(() => { loadPhones() }, [])

    return <>
        <Group>
            <Avatar size={40} src="/assets/twilio.png" />
            <Title c="gray.0" order={5}>Twilio</Title>
        </Group>
        {
            items.length > 0
                ? <Grid mt="md">
                    {items.map(twilio => (
                        (twilio.availablePhones ?? []).map((ph: any) => {
                            const isConnected = phones.some(p => p.number === ph.phoneNumber);
                            return (
                                <Grid.Col span={{ base: 12, md: 4 }}>
                                    <Paper p="md" shadow="xs" style={{ backgroundColor: theme.colors.dark[8] }}>
                                        <Stack>
                                            <Text c="gray.0" ta="center" size="sm">{ph.phoneNumber}</Text>
                                            <Button
                                                size="xs"
                                                variant={isConnected ? 'filled' : "outline"}
                                                loading={connecting[ph.sid]}
                                                onClick={() => {
                                                    setConnecting((c: any) => ({ ...c, [ph.sid]: true }));
                                                    SAVE_PHONE({
                                                        type: "new",
                                                        number: ph.phoneNumber,
                                                        twilio_id: twilio._id,
                                                        sid: ph.sid
                                                    })
                                                        .then(() => {
                                                            loadPhones();
                                                            loadData();
                                                            notifications.show({ message: "Phone connected", color: "green" })
                                                        })
                                                        .catch((err) => {
                                                            notifications.show({ title: "Ops.", message: err.message, color: 'red' })
                                                        })
                                                        .finally(() => {
                                                            setConnecting((c: any) => ({ ...c, [ph.sid]: false }));
                                                        })
                                                }}
                                            >
                                                {isConnected ? 'Reconnect' : 'Connect'}
                                            </Button>
                                        </Stack>
                                    </Paper>
                                </Grid.Col>
                            )
                        })
                    ))}
                </Grid>
                : loadingItems
                    ? <Loader />
                    : <Stack>
                        <InputField
                            name="account_sid"
                            value={data?.account_sid}
                            fieldType={InputFieldTypes.PASSWORD}
                            onChange={handleChange}
                            title="ACCOUNT_SID"
                        />
                        <InputField
                            name="auth_token"
                            value={data?.auth_token}
                            fieldType={InputFieldTypes.PASSWORD}
                            onChange={handleChange}
                            title="AUTH_TOKEN"
                        />

                        <Group justify="flex-end">
                            <Button
                                disabled={
                                    ((data?.account_sid ?? "").length < 10) || (data?.auth_token ?? "").length < 10
                                }
                                size="md"
                                onClick={handleSave}
                                loading={loadingSave}
                                color="green"
                            >Connect</Button>
                        </Group>
                    </Stack>
        }
    </>
}
